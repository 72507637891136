import React, { useState } from "react";
import { useTranslation } from "react-i18next";

//  Features.
import PinReset from "./PinReset";

//  Components.
import { Col, Row } from "react-bootstrap";
import { SuspenseLoader } from "components/graphics";
import BevelButton from "components/inputs/BevelButton";

//  Notifications.
import { store } from "react-notifications-component";
import {
  successNotification,
  dangerNotification,
} from "components/notifications";
import { PlainCard } from "components/layout/cards";
import useUserProfile from "hooks/account/useUserProfile";
import TrackingAPI from "services/tracking/TrackingAPI";
import { useRecovery } from "@neurosolutionsgroup/authentication";

const Component = () => {
  const { t } = useTranslation(["settings", "forms"]);

  const [isLoading, setIsLoading] = useState(false);

  const {
    selectors: { userProfile },
  } = useUserProfile();

  const {
    actions: { requestPasswordReset },
  } = useRecovery();

  const resetPasswordRequestHandler = async () => {
    setIsLoading(true);
    try {
      await requestPasswordReset({
        email: userProfile.email,
      });

      //  Track mixpanel event.
      TrackingAPI.Account.passwordResetRequested().catch(() => {
        console.log("[Recovery] Tracking error");
      });

      store.addNotification(successNotification("", t("auth.email.success")));
    } catch (err) {
      store.addNotification(
        dangerNotification(
          t("forms:errors.title"),
          t("forms:errors.general") + ": " + t("forms:errors." + err.message),
          false
        )
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mt-3">
      <h2>{t("auth.title")}</h2>
      <Row>
        <Col md={6} className="mt-3">
          <PlainCard className="p-3" outerClasses="h-100">
            {userProfile.type === "fb" ? (
              <div>
                <h3>{t("auth.facebook.title")}</h3>

                <p>{t("auth.facebook.message")}</p>
              </div>
            ) : (
              <div className="d-flex flex-column h-100">
                <h3>{t("auth.email.title")}</h3>

                <p className="text-justify mb-2">{t("auth.email.message")}</p>

                <div className="mt-auto align-self-end">
                  <BevelButton
                    disabled={isLoading}
                    className="btn btn-primary"
                    onClick={() => resetPasswordRequestHandler()}
                  >
                    {t("auth.email.resetpassword")}{" "}
                    <i className="icon-arrow"></i>
                  </BevelButton>
                </div>
              </div>
            )}
          </PlainCard>
        </Col>
        <Col md={6} className="mt-3">
          <PlainCard className="p-3" outerClasses="h-100">
            <h3>{t("auth.inapp.title")}</h3>

            <p>{t("auth.inapp.pin.message")}</p>

            <div className="mt-2">
              <PinReset />
            </div>
          </PlainCard>
        </Col>
      </Row>
    </div>
  );
};

export default function SecuritySettings() {
  return (
    <SuspenseLoader>
      <Component />
    </SuspenseLoader>
  );
}
