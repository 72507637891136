const check = () => {
  return window.fbq ? true : false;
};

const trackRegistration = () => {
  track("AccountCreated");
};

const trackRegistrationCompleted = () => {
  track("AccountCreationCompleted");
};

const trackSMSRequested = () => {
  track("SMSRequested");
};

const trackAppleLinkClicked = () => {
  track("AppleLinkClicked");
};

const trackPlayStoreLinkClicked = () => {
  track("PlayStoreLinkClicked");
};

const track = (string) => {
  if (window.fbq) {
    window.fbq("track", "WebApp" + string);
  }
};

const FB = {
  check,
  trackRegistration,
  trackRegistrationCompleted,
  trackSMSRequested,
  trackAppleLinkClicked,
  trackPlayStoreLinkClicked,
};

export default FB;
