import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SuspenseLoader } from "components/graphics";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TextInput } from "components/forms";
import { BevelButton } from "components/inputs";

//  Notifications.
import { store } from "react-notifications-component";
import {
  successNotification,
  dangerNotification,
} from "components/notifications";
import { PlainCard } from "components/layout/cards";
import { Row, Col } from "react-bootstrap";
import { CardPage } from "components/layout/pages";
import { stringHasher } from "services/Hasher";
import usePromoCodes from "hooks/billing/usePromoCodes";

const Component = () => {
  const [t] = useTranslation(["promocode", "forms"]);

  const {
    actions: { redeemPromoCode, redeemExtendedTrial },
  } = usePromoCodes();

  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <CardPage>
        <PlainCard className="billing-section-promo mx-3">
          <Row className="h-100">
            <Col md={6} className="bg-red pr-0">
              <div className="h-100 d-flex p-5 align-items-center justify-content-center">
                <img
                  className="mw-100"
                  alt="Kairos"
                  src={
                    require("assets/kairoslogos/" +
                      t("images:logosimple") +
                      ".png").default
                  }
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="h-100 p-3 d-flex flex-column justify-content-center align-items-center">
                <div>
                  <div className="mb-3">
                    <h3>{t("title")}</h3>
                  </div>
                  <div className="mb-5">
                    <p>{t("message")}</p>
                  </div>

                  <Formik
                    initialValues={{
                      code: "",
                    }}
                    validationSchema={Yup.object({
                      code: Yup.string().required(t("forms:errors.required")),
                    })}
                    onSubmit={async (values) => {
                      setIsLoading(true);
                      try {
                        if (
                          stringHasher(values.code.toUpperCase()) === 1588044992
                        ) {
                          await redeemExtendedTrial();

                          store.addNotification(
                            successNotification("", t("extendedTrial.success"))
                          );
                        } else {
                          await redeemPromoCode(values.code);

                          store.addNotification(
                            successNotification("", t("success"))
                          );
                        }
                      } catch (err) {
                        store.addNotification(
                          dangerNotification(
                            t("forms:errors.title"),
                            t("forms:errors.general") +
                              ": " +
                              t("forms:errors." + err.message),
                            false
                          )
                        );
                      } finally {
                        setIsLoading(false);
                      }
                    }}
                  >
                    <Form>
                      <div className="form-group">
                        <TextInput
                          name="code"
                          type="text"
                          label={t("promocode.label")}
                        />
                      </div>
                      <div className="form-group d-flex justify-content-end">
                        <BevelButton
                          type="submit"
                          className="btn btn-primary"
                          disabled={isLoading}
                        >
                          {t("submit")}
                        </BevelButton>
                      </div>
                    </Form>
                  </Formik>
                </div>
              </div>
            </Col>
          </Row>
        </PlainCard>
      </CardPage>
    </>
  );
};

export default function PromoCode() {
  return (
    <SuspenseLoader>
      <Component />
    </SuspenseLoader>
  );
}
