import React, { useContext, useEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import Dashboard from "./dashboard/Dashboard";
import Page from "../../components/layout/pages/Page";
import Settings from "./settings/Settings";
import AppHeader from "components/layout/header/AppHeader";
import Billing from "./billing/Billing";
import PromoCode from "./billing/promo/PromoCode";
import Routines from "./settings/functional/routines/Routines";
import EmailConfirmationAlert from "components/alerts/EmailConfirmationAlert";
import { Col, Row } from "react-bootstrap";
import NoChildrenAlert from "components/alerts/NoChildren";
import queryString from "query-string";
import AppDataContext, { AppDataProvider } from "hooks/data/AppDataContext";
import useUsers from "hooks/data/useUsers";
import useUserProfile from "hooks/account/useUserProfile";
import { RoutineProvider } from "hooks/data/RoutineContext";
import { UserProvider } from "hooks/data/UserContext";
import { TaskProvider } from "hooks/data/TaskContext";
import { UserProfileProvider } from "hooks/account/UserProfileContext";
import { Loader } from "@neurosolutionsgroup/components";

const ParentAppComponent = () => {
  const urlPath = useLocation().pathname;
  const urlParams = useLocation().search;
  let params = queryString.parse(urlParams);
  const history = useHistory();

  const {
    selectors: { userProfile },
  } = useUserProfile();

  const { dataFetched: appDataFetched } = useContext(AppDataContext);
  const {
    selectors: { userIds },
  } = useUsers();

  useEffect(() => {
    if (params.redirect) {
      let redirect = params.redirect.toString();

      const queryParams = new URLSearchParams(urlParams);

      if (queryParams.has("redirect")) {
        queryParams.delete("redirect");
      }

      params.redirect = null;

      history.push({
        pathname: redirect,
        search: queryParams.toString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const emailExceptions = ["/app/billing"];
  return (
    <div className="background-body background-beige w-100 h-100">
      <Route path="/app" component={AppHeader} />
      {appDataFetched ? (
        <Page>
          {!userProfile.isEmailConfirmed &&
          !emailExceptions.includes(urlPath) ? (
            <Row className="mb-3">
              <Col md={6}>
                <EmailConfirmationAlert />
              </Col>
            </Row>
          ) : null}
          <Switch>
            <Route exact path="/app/billing" component={Billing} />
            <Route exact path="/app/promocode" component={PromoCode} />
            <Route path="/app/settings" component={Settings} />

            {userIds.length > 0 ? (
              <>
                <Route path="/app/routines" component={Routines} />
                <Route path="/app/dashboard" component={Dashboard} />

                <Redirect to="/app/dashboard" />
              </>
            ) : (
              <NoChildrenAlert />
            )}
          </Switch>
        </Page>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default function ParentApp() {
  return (
    <RoutineProvider>
      <UserProvider>
        <TaskProvider>
          <AppDataProvider>
            <UserProfileProvider>
              <ParentAppComponent />
            </UserProfileProvider>
          </AppDataProvider>
        </TaskProvider>
      </UserProvider>
    </RoutineProvider>
  );
}
