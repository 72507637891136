import React from "react";
import { useTranslation } from "react-i18next";

//  Notifications.
import { store } from "react-notifications-component";
import {
  dangerNotification,
  successNotification,
} from "components/notifications/notification";

//  Components.
import { CardPage } from "components/layout/pages";
import { LogoCard } from "components/layout/cards";
import { SuspenseLoader } from "components/graphics";
import TrackingAPI from "services/tracking/TrackingAPI";
import {
  AuthError,
  RecoverAccountPage,
} from "@neurosolutionsgroup/authentication";

const Component = () => {
  //  Localization.
  const { t } = useTranslation(["account", "forms"]);

  const onSuccess = () => {
    //  Track mixpanel event.
    TrackingAPI.Account.passwordResetRequested().catch(() => {
      console.log("[Recovery] Tracking error");
    });

    store.addNotification(
      successNotification("", t("forgottenpassword.success"))
    );
  };

  const onFailure = (err: AuthError) => {
    store.addNotification(
      dangerNotification(
        t("forms:errors.general"),
        t("forgottenpassword.error")
      )
    );
  };

  return (
    <CardPage>
      <LogoCard className="bg-beige">
        <RecoverAccountPage onSuccess={onSuccess} onFailure={onFailure} />
      </LogoCard>
    </CardPage>
  );
};

export default function RecoverAccount() {
  return (
    <SuspenseLoader>
      <Component />
    </SuspenseLoader>
  );
}
