import * as PlayFabAPI from "services/api/playFabApi/account/Account";
import axios from "axios";
import { UserProfile } from "services/UserProfile";

async function getUserData(sessionTicket: string) {
  return axios
    .get(process.env.REACT_APP_KAIROS_API_ENDPOINT + "/api/Account", {
      headers: {
        Authorization: sessionTicket,
      },
    })
    .then((response) => {
      return response.data as UserProfile;
    })
    .catch((error) => {
      console.log(error);
      return Promise.reject(error);
    });
}

async function updateUserData(data: any, sessionTicket: string) {
  var headers = {
    Authorization: sessionTicket,
  };

  const email = data.userInternalData.email;

  return PlayFabAPI.updateContactEmail(email, sessionTicket)
    .then(() => {
      return axios
        .put(process.env.REACT_APP_KAIROS_API_ENDPOINT + "/api/Account", data, {
          headers: headers,
        })
        .then((response) => {
          return response.data as UserProfile;
        })
        .catch((error) => {
          console.log(error);
          return Promise.reject(error);
        });
    })
    .catch((error) => {
      console.log(error);
      return Promise.reject(error);
    });
}

export { getUserData, updateUserData };
