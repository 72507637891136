import {
  PlayFabRoutine,
  PlayFabUser,
  Routine,
  Task,
  TaskInstance,
  User,
} from "models/Types";

export type NormalizrResult = {
  entities: {
    tasks: { [key: string]: Task };
    users: { [key: string]: User };
    routines: { [key: string]: Routine };
  };
  result: {
    users: string[];
    routines: string[];
  };
};

export const parsePlayFabUserData = (playFabUsers: PlayFabUser[]) => {
  var users: User[] = [];

  //  Filter tasks already confirmed.
  playFabUsers.forEach((user: PlayFabUser) => {
    var tasksToValidate: TaskInstance[] = [];

    if (user.taskHistory) {
      user.taskHistory.history
        .filter((task: TaskInstance) =>
          task !== null ? task.confirmTime === null : false
        )
        .forEach((task: TaskInstance) => {
          tasksToValidate.push(task);
        });
      tasksToValidate.sort((a, b) => (a.dueTime > b.dueTime ? 1 : -1));
    }

    users.push({
      id: user.id,
      name: user.name,
      icon: user.icon,
      pin: user.nip,
      useImageNip: user.useImageNip,
      taskListVersion: user.taskHistory ? user.taskHistory.version : null,
      tasksToValidate: tasksToValidate,
    });
  });

  return users;
};

export const parsePlayFabRoutineData = (playFabRoutines: PlayFabRoutine[]) => {
  var routines: PlayFabRoutine[] = [];

  //  Filter disabled or deleted routines.
  playFabRoutines.forEach((routine) => {
    routine.tasks.forEach((task) => {
      task.routine = routine.id;
    });

    routines.push(routine);
  });

  return routines;
};
