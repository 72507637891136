import React, { ReactNode, SetStateAction, useState } from "react";
import { Price, Coupon } from "./types";

export interface StripeContextData {
  prices: Price[];
  setPrices: (prices: Price[]) => void;
  referralCoupon: Coupon | null;
  setReferralCoupon: React.Dispatch<SetStateAction<Coupon | null>>;
}

export const StripeContextDefaultData: StripeContextData = {
  prices: [],
  setPrices: () => {},
  referralCoupon: null,
  setReferralCoupon: () => {},
};

const StripeContext = React.createContext<StripeContextData>(
  StripeContextDefaultData
);

export type Props = {
  children: ReactNode;
};

export const StripeProvider = (props: Props) => {
  const [prices, setPrices] = useState<Price[]>([]);
  const [referralCoupon, setReferralCoupon] = useState<Coupon | null>(null);

  return (
    <StripeContext.Provider
      value={{ prices, setPrices, referralCoupon, setReferralCoupon }}
    >
      {props.children}
    </StripeContext.Provider>
  );
};

export default StripeContext;
