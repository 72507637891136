//  Generate time string from seconds.
const getTimeString = (time: number) => {
  return (
    //  Hours.
    (Math.floor(time / 3600) < 10
      ? "0" + Math.floor(time / 3600)
      : Math.floor(time / 3600)) +
    ":" +
    //  Minutes.
    ((time % 3600) / 60 < 10 ? "0" + (time % 3600) / 60 : (time % 3600) / 60)
  );
};

export { getTimeString };
