import { BevelButton } from "components/inputs";
import { PlainCard } from "components/layout/cards";
import React from "react";
import { useTranslation } from "react-i18next";
import ClipboardIcon from "assets/GeneralIcons/icon_clipboard.png";
import { sendConfirmationEmail } from "services/api/kairosApi/account/Security";
//  Notifications.
import { store } from "react-notifications-component";
import {
  successNotification,
  dangerNotification,
} from "components/notifications";
import { useAuth } from "@neurosolutionsgroup/authentication";

const EmailConfirmationAlert = () => {
  const { t } = useTranslation(["alerts"]);

  const {
    actions: { getAuthentication },
  } = useAuth();

  const onSendClick = async () => {
    await sendConfirmationEmail(getAuthentication().sessionTicket)
      .then(() => {
        store.addNotification(successNotification("", t("auth.email.success")));
      })
      .catch((error) => {
        store.addNotification(
          dangerNotification(
            t("forms:errors.title"),
            t("forms:errors.general") +
              ": " +
              t("forms:errors." + error.message),
            false
          )
        );
      });
  };

  return (
    <PlainCard className="alert-emailconfirmation p-3">
      <h2 className="title text-left">{t("emailconfirmation.title")}</h2>
      <div className="d-flex align-items-stretch">
        <div className="icon m-2 mr-3">
          <img src={ClipboardIcon} alt="" />
        </div>
        <div className="d-flex align-items-end flex-column flex-grow-1">
          <div className="text mb-2">
            <p>{t("emailconfirmation.text")}</p>
          </div>
          <div className="button mt-auto ml-auto">
            <BevelButton className="btn btn-primary" onClick={onSendClick}>
              {t("emailconfirmation.button")}
            </BevelButton>
          </div>
        </div>
      </div>
    </PlainCard>
  );
};

export default EmailConfirmationAlert;
