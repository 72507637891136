import { PlainCard } from "components/layout/cards";
import { CardPage } from "components/layout/pages";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface Props {
  thin?: boolean;
}

const NoChildrenAlert = ({ thin = false }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <CardPage>
        <PlainCard className="billing-section-promo mx-3">
          <Row className="h-100">
            {!thin && (
              <Col md={6} className="bg-red pr-0">
                <div className="h-100 d-flex p-5 align-items-center justify-content-center">
                  <img
                    className="mw-100"
                    alt="Kairos"
                    src={
                      require("assets/kairoslogos/" +
                        t("images:logosimple") +
                        ".png").default
                    }
                  />
                </div>
              </Col>
            )}
            <Col md={thin ? 12 : 6}>
              <div className="h-100 p-3 d-flex flex-column justify-content-around align-items-center">
                <div className="my-1">
                  <h3>{t("nochildren.title")}</h3>
                </div>
                <div className="mb-2">
                  <p>{t("nochildren.message")}</p>
                </div>
                <a className="mb-2" href="https://kairos.app.link/QWz3mlTKqcb">
                  <img
                    className="Kairos_ads_test"
                    src="https://kairosgame.com/media/1313/google_play_logo_kairos_300px.png"
                    alt=""
                  />
                </a>
                <a className="mb-2" href="https://kairos.app.link/QWz3mlTKqcb">
                  <img
                    className="Kairos_ads_test"
                    src="https://kairosgame.com/media/1314/app_store_logo_white_kairos_300px.png"
                    alt=""
                  />
                </a>
              </div>
            </Col>
          </Row>
        </PlainCard>
      </CardPage>
    </>
  );
};

export default NoChildrenAlert;
