import React, { FunctionComponent } from "react";

const BevelBorderButton: FunctionComponent<
  React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ className, ...props }) => {
  return (
    <div className="button-wrap">
      <button
        className={className + " p-0 d-flex align-items-stretch"}
        {...props}
      >
        <div className="bevel-border-button-inner flex-grow-1 d-flex justify-content-center align-items-center">
          {props.children}
        </div>
      </button>
    </div>
  );
};

export default BevelBorderButton;
