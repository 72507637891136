import React, { FunctionComponent } from "react";
import { FieldConfig, useField, useFormikContext } from "formik";
import { Moment } from "moment";
import TimePicker from "rc-time-picker";
import moment from "moment";

interface TimePickerFieldProps extends FieldConfig {
  label?: string;
  disabledHours?: () => number[];
  disabledMinutes?: (h: number) => number[];
}

const TimePickerField: FunctionComponent<TimePickerFieldProps> = ({
  label,
  disabledHours,
  disabledMinutes,
  ...props
}) => {
  const [field, meta] = useField<number>(props);

  const { setFieldValue, setFieldTouched } = useFormikContext();

  return (
    <>
      {label ? <label htmlFor={field.name}>{label}</label> : null}
      <div className="input-wrap">
        <TimePicker
          value={moment.utc(field.value * 1000)}
          onChange={(date: Moment) => {
            setFieldTouched(field.name, true);
            setFieldValue(
              field.name,
              date.get("hour") * 3600 + date.get("minute") * 60
            );
          }}
          showSecond={false}
          minuteStep={15}
          allowEmpty={false}
          className="form-control m-0 p-0"
          disabledHours={disabledHours}
          disabledMinutes={disabledMinutes}
          hideDisabledOptions={true}
        />
      </div>
      {meta.touched && meta.error ? (
        <div className="form-error">{meta.error}</div>
      ) : null}
    </>
  );
};

export default TimePickerField;
