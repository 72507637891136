import React, { useState } from "react";
import { useTranslation } from "react-i18next";

//  Notifications.
import { store } from "react-notifications-component";
import {
  successNotification,
  dangerNotification,
} from "components/notifications";

//  Components.
import Dropdown from "components/inputs/Dropdown/Dropdown";
import useUserProfile from "hooks/account/useUserProfile";
import { Loader } from "@neurosolutionsgroup/components";

const Language = () => {
  //  Hooks.
  //  Localization.
  const { t } = useTranslation(["settings", "forms"]);

  //  State.
  const [isLoading, setIsLoading] = useState(false);

  const {
    actions: { updateLanguage },
  } = useUserProfile();

  const changeLanguage = async (lang: string) => {
    if (!isLoading) {
      setIsLoading(true);

      updateLanguage(lang)
        .then(() => {
          store.addNotification(
            successNotification(
              t("account.settings.language.success.title"),
              t("account.settings.language.success.message")
            )
          );
        })
        .catch((err) => {
          store.addNotification(
            dangerNotification(
              t("forms:errors.title"),
              t("forms:errors.general") +
                ": " +
                t("forms:errors." + err.message)
            )
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      <Dropdown
        className="mb-2"
        toggle={t("account.settings.language.label")}
        links={[
          {
            onClick: () => changeLanguage("en"),
            text: "EN",
          },
          {
            onClick: () => changeLanguage("fr"),
            text: "FR",
          },
        ]}
      />
    </>
  );
};

export default Language;
