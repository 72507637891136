import { User } from "models/Types";
import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useState,
} from "react";

export type UsersById = {
  [key: string]: User;
};

export interface UserContextData {
  userIds: string[];
  setUserIds: Dispatch<SetStateAction<string[]>>;
  usersById: UsersById;
  setUsersById: Dispatch<SetStateAction<UsersById>>;
  selectedUserId: string;
  setSelectedUserId: Dispatch<SetStateAction<string>>;
}

const UserContext = React.createContext<UserContextData>(null!);

export const UserProvider = (props: PropsWithChildren<any>) => {
  const [userIds, setUserIds] = useState<string[]>([]);
  const [usersById, setUsersById] = useState<UsersById>({});
  const [selectedUserId, setSelectedUserId] = useState<string>(undefined!);

  return (
    <UserContext.Provider
      value={{
        userIds,
        setUserIds,
        usersById,
        setUsersById,
        selectedUserId,
        setSelectedUserId,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContext;
