import React, { FunctionComponent } from "react";
import { useField } from "formik";

interface TextInputProps {
  label?: string;
  className?: string;
  wrapClasses?: string;
  name: string;
  type: string;
  [key: string]: any;
}

//  TextInput for use with Formik.
const TextInput: FunctionComponent<TextInputProps> = ({
  label,
  className,
  wrapClasses,
  ...props
}) => {
  const [field, meta] = useField(props);
  return (
    <>
      {label ? <label htmlFor={props.name}>{label}</label> : null}
      <div className={"input-wrap " + wrapClasses}>
        <input {...field} {...props} className={"form-control " + className} />
      </div>
      {meta.touched && meta.error ? (
        <div className="form-error">{meta.error}</div>
      ) : null}
    </>
  );
};

export default TextInput;
