import { CustomWindow } from "custom.window";

declare let window: CustomWindow;

export type CleverTapProperty = string | boolean | number | Date;

const trackEvent = (
  eventName: string,
  eventProperties?: { [key: string]: CleverTapProperty }
): void => {
  eventProperties
    ? window.cleverTap.event.push(eventName, eventProperties)
    : window.cleverTap.event.push(eventName);
};

const onUserLogin = (id: string, email: string): void => {
  window.cleverTap.onUserLogin.push({ Site: { Identity: id, Email: email } });
};

const profilePush = (eventProperties: {
  [key: string]: CleverTapProperty;
}): void => {
  window.cleverTap.profile.push({ Site: eventProperties });
};

const CleverTap = { trackEvent, onUserLogin, profilePush };

export default CleverTap;
