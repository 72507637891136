import React, { FunctionComponent } from "react";

export const BevelButton: FunctionComponent<
  React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ className, ...props }) => {
  return (
    <div className="button-wrap">
      <button className={"px-4 " + className} {...props}>
        {props.children}
      </button>
    </div>
  );
};

export default BevelButton;
