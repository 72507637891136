import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Error from "./Error";
import Links from "./links/Links";
import EmailConfirmed from "./EmailConfirmed";

import Header from "components/layout/header/Header";
import PaymentSuccessful from "./Payment/PaymentSuccessful";
import PaymentFailed from "./Payment/PaymentFailed";

const Callbacks = () => {
  return (
    <div className="background-body background">
      <Route path="/callbacks">
        <Header />
      </Route>
      <Switch>
        <Route path="/callbacks/links" component={Links} />
        <Route path="/callbacks/error" component={Error} />
        <Route path="/callbacks/emailConfirmed" component={EmailConfirmed} />
        <Route
          path="/callbacks/payment-successful"
          component={PaymentSuccessful}
        />
        <Route path="/callbacks/payment-failed" component={PaymentFailed} />
        <Redirect to="/" />
      </Switch>
    </div>
  );
};

export default Callbacks;
