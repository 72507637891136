import React from "react";
import { useTranslation } from "react-i18next";

//  Form.
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TextInput } from "@neurosolutionsgroup/inputs";

//  Notifications.
import { store } from "react-notifications-component";
import {
  successNotification,
  dangerNotification,
} from "components/notifications";

//  Components.
import { BevelButton } from "components/inputs";
import Language from "./Language";
import { PlainCard } from "components/layout/cards";
import useUserProfile from "hooks/account/useUserProfile";
import { Loader } from "@neurosolutionsgroup/components";

const AccountSettings = () => {
  //  Localization.
  const { t } = useTranslation(["settings", "forms"]);

  const {
    selectors: { userProfile },
    actions: { updateUserProfile },
  } = useUserProfile();

  return (
    <div>
      <PlainCard className="p-3">
        <h2 className="text-left mb-3">{t("account.title")}</h2>

        <Language />

        <hr />
        <Formik
          initialValues={{
            firstName: userProfile.firstName,
            lastName: userProfile.lastName,
            city: userProfile.city,
            email: userProfile.email,
          }}
          enableReinitialize={true}
          validationSchema={Yup.object({
            firstName: Yup.string().required(t("forms:errors.required")),
            lastName: Yup.string().required(t("forms:errors.required")),
            city: Yup.string(),
            email: Yup.string()
              .email(t("forms:errors.invalidemail"))
              .required(t("forms:errors.required")),
          })}
          onSubmit={async (values) => {
            try {
              await updateUserProfile(values);

              store.addNotification(
                successNotification(
                  t("account.success.title"),
                  t("account.success.message")
                )
              );
            } catch (err) {
              store.addNotification(
                dangerNotification(
                  t("forms:errors.title"),
                  t("forms:errors.general") +
                    ": " +
                    t("forms:errors." + err.message)
                )
              );
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              {isSubmitting ? <Loader /> : null}
              <TextInput
                name="firstName"
                type="text"
                label={t("account.settings.firstname.label")}
              />
              <TextInput
                name="lastName"
                type="text"
                label={t("account.settings.lastname.label")}
              />
              <TextInput
                name="city"
                type="text"
                label={t("account.settings.city.label")}
              />
              <TextInput
                name="email"
                type="text"
                label={t("account.settings.email.label")}
              />
              <BevelButton
                type="submit"
                className="btn btn-primary w-100"
                disabled={isSubmitting}
              >
                {t("account.submit")}
              </BevelButton>
            </Form>
          )}
        </Formik>
      </PlainCard>
    </div>
  );
};

export default AccountSettings;
