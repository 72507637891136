import React, { PropsWithChildren, useEffect, useState } from "react";
import UserProfileService, { UserProfile } from "services/UserProfile";
import * as API from "services/api/kairosApi";
import { useAuth } from "@neurosolutionsgroup/authentication";

export interface UserProfileContextData {
  dataFetched: boolean;
  userProfile: UserProfile;
  setUserProfile: (userProfile: UserProfile) => void;
}

const UserProfileContext = React.createContext<UserProfileContextData>(null!);

export const UserProfileProvider = (props: PropsWithChildren<any>) => {
  const [dataFetched, setDataFetched] = useState(false);
  const [userProfile, setUserProfile] = useState<UserProfile>(
    UserProfileService.getUserProfile()
  );

  const {
    actions: { getAuthentication },
  } = useAuth();

  useEffect(() => {
    API.Account.Data.getUserData(getAuthentication().sessionTicket).then(
      (profile) => {
        UserProfileService.setUserProfile(profile);
        setUserProfile(UserProfileService.getUserProfile());
        setDataFetched(true);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateUserProfile = (profile: UserProfile) => {
    UserProfileService.setUserProfile(profile);
    setUserProfile(UserProfileService.getUserProfile());
  };

  return (
    <UserProfileContext.Provider
      value={{
        dataFetched,
        userProfile,
        setUserProfile: updateUserProfile,
      }}
    >
      {props.children}
    </UserProfileContext.Provider>
  );
};

export default UserProfileContext;
