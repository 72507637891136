import React, { Suspense, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomPhoneInput from "components/forms/PhoneInput";

import { CardPage } from "components/layout/pages";
import { LogoCard } from "components/layout/cards";
import { BevelButton } from "components/inputs/BevelButton";

import { store } from "react-notifications-component";
import {
  successNotification,
  dangerNotification,
} from "components/notifications";
import TrackingAPI from "services/tracking";
import { AppStore } from "services/tracking/TrackingAPI";
import { Loader } from "@neurosolutionsgroup/components";

function Component() {
  const { t } = useTranslation(["account", "forms", "base"]);

  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  //  Setup branch.io.
  (function (b, r, a, n, c, h, _, s, d, k) {
    if (!b[n] || !b[n]._q) {
      for (; s < _.length; ) c(h, _[s++]);
      d = r.createElement(a);
      d.async = 1;
      d.src = "https://cdn.branch.io/branch-latest.min.js";
      k = r.getElementsByTagName(a)[0];
      k.parentNode.insertBefore(d, k);
      b[n] = h;
    }
  })(
    window,
    document,
    "script",
    "branch",
    function (b, r) {
      b[r] = function () {
        b._q.push([r, arguments]);
      };
    },
    { _q: [], _v: 1 },
    "addListener applyCode banner closeBanner creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode".split(
      " "
    ),
    0
  );

  window.branch.init("key_live_gpS1hUh0qIZsNPJLuJhmBehfDthDtLbM");

  //  Send SMS.
  const sendSMS = (tel) => {
    var phone = tel;
    var linkData = {
      tags: [],
      channel: "Website",
      feature: "TextMeTheApp",
      data: {
        message: "Test App Message {{link}}",
      },
    };
    var options = {};
    var callback = function (err, result) {
      if (err) {
        console.log(err);
        setLoading(false);
        store.addNotification(dangerNotification(null, t("links.error")));
      } else {
        TrackingAPI.Account.smsRequested();

        setLoading(false);
        store.addNotification(successNotification(null, t("links.success")));
      }
    };
    window.branch.sendSMS(phone, linkData, options, callback);
  };

  useEffect(() => {
    checkMobile();
  }, []);

  const clickTrack = (store) => {
    TrackingAPI.Account.downloadLinkOpened(store);
  };

  const checkMobile = () => {
    let check = false;

    (function (a) {
      if (
        // eslint-disable-next-line no-useless-escape
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        // eslint-disable-next-line no-useless-escape
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    setIsMobile(check);
  };

  return (
    <CardPage>
      <LogoCard className="bg-beige">
        {loading ? <Loader /> : null}
        <div>
          <h2>{t("links.title")}</h2>
          <hr />
          <p className="text-center">{t("links.subtitle")}</p>
          {isMobile ? null : <p className="text-center">{t("links.text")}</p>}
        </div>
        {isMobile ? (
          <div>
            <hr />
            <div>
              <div>
                <a
                  href="https://kairos.app.link/hvKZt0EXJ7"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => clickTrack(AppStore.Google)}
                >
                  <img
                    alt={t("base:components.googleplayalt")}
                    className="w-100"
                    src={
                      "https://play.google.com/intl/en_us/badges/static/images/badges/" +
                      t("base:components.googleplaybadge") +
                      "_badge_web_generic.png"
                    }
                  />
                </a>
              </div>
            </div>
            <div className="w-100">
              <div>
                <a
                  href="https://kairos.app.link/hvKZt0EXJ7"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => clickTrack(AppStore.Apple)}
                >
                  <img
                    alt={t("base:components.appstorealt")}
                    className="w-100 p-4"
                    src={
                      require("assets/appstore/AppStore" +
                        t("base:components.appstorebadge") +
                        ".svg").default
                    }
                  />
                </a>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <hr />
            <h5 className="text-center mb-3">{t("links.sms")}</h5>
            <Formik
              initialValues={{
                tel: "",
              }}
              validationSchema={Yup.object({
                tel: Yup.string()
                  .required(t("forms:errors.required"))
                  .matches("^[0-9]{10,13}$", t("links.telerror")),
              })}
              onSubmit={async (values) => {
                setLoading(true);
                var telFormat = "+" + values.tel;
                sendSMS(telFormat);
              }}
            >
              <Form>
                <div className="form-group links-phone">
                  <CustomPhoneInput label={t("links.tellabel")} name="tel" />
                </div>
                <div className="form-group links-button">
                  <BevelButton type="submit" className="btn btn-primary w-100">
                    {t("links.smsbutton")}
                  </BevelButton>
                </div>
              </Form>
            </Formik>
          </div>
        )}
      </LogoCard>
    </CardPage>
  );
}

export default function Links() {
  return (
    <Suspense fallback="loading">
      <Component />
    </Suspense>
  );
}
