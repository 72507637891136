import { BevelButton } from "components/inputs";
import { PlainCard } from "components/layout/cards";
import useUserProfile from "hooks/account/useUserProfile";
import { useAuth } from "@neurosolutionsgroup/authentication";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as API from "services/api/kairosApi";
import {
  EmailShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";
import { store } from "react-notifications-component";
import { successNotification } from "components/notifications";

const Billing = () => {
  const { t } = useTranslation(["settings", "referral"]);

  const {
    selectors: { userProfile },
  } = useUserProfile();

  const {
    actions: { getAuthentication },
  } = useAuth();

  const openPortal = () => {
    API.Account.Stripe.createPortalSession(
      getAuthentication().sessionTicket
    ).then((response) => {
      window.open(response.redirectUrl);
    });
  };

  return (
    <div className="mt-3">
      <h2>{t("billing.title")}</h2>
      <PlainCard className="p-3">
        {userProfile.isSubscribed ? (
          userProfile.isStripe ? (
            <div>
              <p>{t("billing.text.stripe.text")}</p>
              <BevelButton
                className="btn btn-primary mt-2 float-right"
                onClick={openPortal}
              >
                {t("billing.text.stripe.button")}
              </BevelButton>
            </div>
          ) : (
            <div>
              <p>{t("billing.text.app")}</p>
            </div>
          )
        ) : (
          <div>
            <span>{t("billing.text.nosubscription.text")}</span>
            <Link to="/app/billing">
              {t("billing.text.nosubscription.link")}
            </Link>
          </div>
        )}
      </PlainCard>
      {getAuthentication().playFabId ? (
        <PlainCard className="my-3 referral-card">
          <h2>{t("referral:share.title")}</h2>

          <p>{t("referral:share.subtitle")}</p>

          <a href="https://kairosgame.com/referer-un-ami">
            {t("referral:share.link")}
          </a>

          <div className="row">
            <div className="col-md-4">
              <img
                src="https://kairosgame.com/media/1748/kairos-refere-un-ami.png"
                alt=""
              />
              <h5>{t("referral:share.information.link.title")}</h5>
              <p>{t("referral:share.information.link.text")}</p>
            </div>
            <div className="col-md-4">
              <img
                src="https://kairosgame.com/media/1747/kairos-recoit-des-recompenses.png"
                alt=""
              />
              <h5>{t("referral:share.information.discount.title")}</h5>
              <p>{t("referral:share.information.discount.text")}</p>
            </div>
            <div className="col-md-4">
              <img
                src="https://kairosgame.com/media/1746/kairos-costumes-amusants.png"
                alt=""
              />
              <h5>{t("referral:share.information.reward.title")}</h5>
              <p>{t("referral:share.information.reward.text")}</p>
            </div>
          </div>

          <div className="d-flex justify-content-center my-4">
            <div className="d-flex w-100">
              <input
                readOnly
                value={`https://staging.app.kairosgame.com?referrer=${
                  getAuthentication().playFabId
                }&referrerName=${userProfile.firstName}`}
              />
              <div
                onClick={() => {
                  navigator.clipboard.writeText(
                    `https://staging.app.kairosgame.com?referrer=${
                      getAuthentication().playFabId
                    }&referrerName=${userProfile.firstName}`
                  );
                  store.addNotification(
                    successNotification("", t("referral:share.copied"), true)
                  );
                }}
              >
                <i className="icon-copy icon-lg"></i>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <FacebookShareButton
              url={`https://staging.app.kairosgame.com?referrer=${
                getAuthentication().playFabId
              }&referrerName=${userProfile.firstName}`}
              quote={t("referral:share.sharetext")}
            >
              <FacebookIcon />
            </FacebookShareButton>
            <WhatsappShareButton
              url={`https://staging.app.kairosgame.com?referrer=${
                getAuthentication().playFabId
              }&referrerName=${userProfile.firstName}`}
              title={t("referral:share.sharetext")}
            >
              <WhatsappIcon />
            </WhatsappShareButton>
            <TwitterShareButton
              url={`https://staging.app.kairosgame.com?referrer=${
                getAuthentication().playFabId
              }&referrerName=${userProfile.firstName}`}
              title={t("referral:share.sharetext")}
            >
              <TwitterIcon />
            </TwitterShareButton>
            <EmailShareButton
              url={`https://staging.app.kairosgame.com?referrer=${
                getAuthentication().playFabId
              }&referrerName=${userProfile.firstName}`}
              body={t("referral:share.sharetext")}
            >
              <EmailIcon />
            </EmailShareButton>
          </div>
        </PlainCard>
      ) : null}
    </div>
  );
};

export default Billing;
