import { HookResult } from "hooks/HookResult";
import { useContext } from "react";
import StripeContext from "./StripeContext";
import { Coupon, Price } from "./types";
import * as API from "services/api/kairosApi";

export interface useStripeSelectors {
  prices: Price[];
  referralCoupon: Coupon | null;
}

export interface useStripeActions {
  getPrices: () => void;
}

const useStripe = (): HookResult<useStripeSelectors, useStripeActions> => {
  const { prices, setPrices, referralCoupon, setReferralCoupon } = useContext(
    StripeContext
  );

  const getPrices = async () => {
    API.Account.Stripe.getPrices().then((response) => {
      setPrices(response.prices);
      setReferralCoupon(response.coupon ?? null);
    });
  };

  return {
    selectors: {
      prices,
      referralCoupon,
    },
    actions: {
      getPrices,
    },
  };
};

export default useStripe;
