import React, { FunctionComponent } from "react";

import { Task } from "models/Types";

import { Modal } from "react-bootstrap";

import { BevelButton } from "components/inputs";
import { useField, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { TaskModalProps } from ".";

const DeleteTaskModal: FunctionComponent<TaskModalProps> = ({
  show,
  close,
  taskId,
  ...props
}) => {
  const { t } = useTranslation("routines");

  const { setFieldValue } = useFormikContext();

  const [field] = useField<Task[]>(props);

  const removeTask = () => {
    var tasks = field.value.map((t) => {
      if (t.id === taskId) {
        return { ...t, deleted: true };
      } else {
        return { ...t };
      }
    });

    setFieldValue(field.name, tasks);

    close();
  };

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header>
        <Modal.Title>{t("edit.tasks.delete.label")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t("edit.tasks.delete.message")}</p>
      </Modal.Body>
      <Modal.Footer>
        <BevelButton
          className="btn btn-primary-alt"
          onClick={close}
          type="button"
        >
          {t("edit.cancel")}
        </BevelButton>
        <BevelButton
          className="btn btn-primary"
          onClick={removeTask}
          type="button"
        >
          {t("edit.tasks.delete.submit")}
        </BevelButton>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteTaskModal;
