import { useField, useFormikContext } from "formik";
import React, { FunctionComponent } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

interface PhoneInputProps {
  label: string;
  name: string;
  type: string;
  id: string;
}

const CustomPhoneInput: FunctionComponent<PhoneInputProps> = ({
  label,
  ...props
}) => {
  const [field, meta] = useField(props);

  const { setFieldValue, setFieldTouched } = useFormikContext();

  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <div className="input-wrap">
        <PhoneInput
          country={"ca"}
          {...field}
          {...props}
          onChange={(number: string) => {
            setFieldValue(field.name, number);
          }}
          onBlur={() => {
            setFieldTouched(field.name, true);
          }}
          dropdownStyle={{ zIndex: 100 }}
          regions={["europe", "north-america"]}
          containerClass="w-100"
          inputClass="w-100 border-0"
        />
      </div>
      {meta.touched && meta.error ? (
        <div className="text-danger">{meta.error}</div>
      ) : null}
    </>
  );
};

export default CustomPhoneInput;
