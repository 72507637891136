import React, { ReactNode } from "react";
import {
  Dropdown as BootstrapDropdown,
  DropdownProps as BootstrapDropdownProps,
} from "react-bootstrap";

interface DropdownProps extends BootstrapDropdownProps {
  toggle: ReactNode;
  links: DropdownLink[];
  wrap?: boolean;
  buttonClasses?: string;
}

export type DropdownLink = {
  onClick: () => void;
  text: ReactNode;
};

const Dropdown = ({
  toggle,
  links,
  wrap = true,
  buttonClasses,
  ...props
}: DropdownProps) => {
  return (
    <BootstrapDropdown {...props}>
      {wrap ? (
        <div className="button-wrap">
          <BootstrapDropdown.Toggle className={buttonClasses}>
            {toggle} <i className="icon-dropdown ml-1"></i>
          </BootstrapDropdown.Toggle>
        </div>
      ) : (
        <BootstrapDropdown.Toggle className={buttonClasses}>
          {toggle} <i className="icon-dropdown ml-1"></i>
        </BootstrapDropdown.Toggle>
      )}

      <BootstrapDropdown.Menu>
        <div className="dropdown-menu-inner">
          {links.map((l, i) => (
            <BootstrapDropdown.Item onClick={() => l.onClick()} key={i}>
              {l.text}
            </BootstrapDropdown.Item>
          ))}
        </div>
      </BootstrapDropdown.Menu>
    </BootstrapDropdown>
  );
};

export default Dropdown;
