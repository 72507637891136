import React, { useEffect, useState } from "react";
import LogoCard from "components/layout/cards/LogoCard";
import CardPage from "components/layout/pages/CardPage";
import { SuspenseLoader } from "components/graphics";
import { Link, useHistory, useLocation } from "react-router-dom";
import TrackingAPI from "services/tracking";
import { AccountType } from "services/tracking/TrackingAPI";
import { dangerNotification } from "components/notifications";
import { store } from "react-notifications-component";
import { useTranslation } from "react-i18next";
import {
  AuthError,
  RegistrationPage,
} from "@neurosolutionsgroup/authentication";
import queryString from "query-string";
import ReferredRegistration from "./ReferredRegistration/ReferredRegistration";

const Component = () => {
  const history = useHistory();
  const { t } = useTranslation(["account", "forms"]);

  const location = useLocation();
  const urlParams = location.search;

  const [referrer, setReferrer] = useState<string | undefined>(undefined);
  const [referrerName, setReferrerName] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    let params = queryString.parse(urlParams);

    if (params.referrer) {
      setReferrer(params.referrer.toString());
    }
    if (params.referrerName) {
      setReferrerName(params.referrerName?.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSuccess = (response: {
    playFabId: string;
    email: string;
    language: string;
  }) => {
    TrackingAPI.Account.userRegistered(
      response.playFabId,
      AccountType.Email,
      response.language,
      response.email,
      referrer
    ).catch(() => {
      // Errors are non-blocking.
      console.log("[Registration] Information update or tracking failed.");
    });

    history.push("/account/completeregistration");
  };
  const onFailure = (err: AuthError) => {
    store.addNotification(
      dangerNotification(
        t("forms:errors.title"),
        t("forms:errors.general") + ": " + t("forms:errors." + err.code),
        false
      )
    );
  };

  return referrer ? (
    <ReferredRegistration referrerName={referrerName}>
      <RegistrationPage
        onSuccess={onSuccess}
        onFailure={onFailure}
        referrerId={referrer}
      />
    </ReferredRegistration>
  ) : (
    <CardPage>
      <LogoCard className="bg-beige">
        <RegistrationPage
          onSuccess={onSuccess}
          onFailure={onFailure}
          referrerId={referrer}
        />

        <hr />

        <p className="text-center">
          {t("registration.menu.forgottendescription")}{" "}
          <Link to="/account/forgottenpassword">
            {t("registration.menu.forgottenlink")}
          </Link>
        </p>
      </LogoCard>
    </CardPage>
  );
};

export default function Registration() {
  return (
    <SuspenseLoader>
      <Component />
    </SuspenseLoader>
  );
}
