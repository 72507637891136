import React, { FunctionComponent } from "react";
import { Form, Formik, useField, useFormikContext } from "formik";
import * as Yup from "yup";

import { PlayFabRoutine, Task } from "models/Types";

import { Modal } from "react-bootstrap";

import { SuspenseLoader } from "components/graphics";
import { BevelButton } from "components/inputs";
import TimePickerField from "components/forms/TimePickerField";
import { useTranslation } from "react-i18next";
import { TaskModalProps } from ".";

const Component: FunctionComponent<TaskModalProps> = ({
  show,
  close,
  taskId,
  ...props
}) => {
  const { t } = useTranslation("routines");

  const { values, setFieldValue } = useFormikContext();

  const [field] = useField<Task[]>(props);

  //  Setup disabled times.
  const disabledHours = () => {
    const tValues = values as PlayFabRoutine;

    //  Array of all possible values.
    const hours = Array.from({ length: 24 }, (_, index) => index);

    //  Filter hours.
    //  All hours less than start hour or greater than the end hour are excluded.
    //  If the end hour is an exact hour the any hour equal to it is excluded also.
    return hours.filter(
      (h) =>
        h < Math.floor(tValues.start / (60 * 60)) ||
        h >= tValues.end / (60 * 60)
    );
  };

  const disabledMinutes = (h: number) => {
    const tValues = values as PlayFabRoutine;

    //  Array of all minutes.
    var minutes = Array.from({ length: 60 }, (_, index) => index);

    //  Calculate the start and end hours.
    const startHour = Math.floor(tValues.start / (60 * 60));
    const endHour = Math.floor(tValues.end / (60 * 60));

    //  If the hour is outside routine hours then all minutes are excluded.
    if (h < startHour || h > endHour) {
      return minutes;
    }

    //  If the hour is between the two, allow all minutes.
    if (h !== startHour || h !== endHour) {
      return [];
    }

    if (h === startHour) {
      const startMinutes = (tValues.start - startHour * 60 * 60) / 60;
      minutes = minutes.filter((m) => m < startMinutes);
    }

    if (h === endHour) {
      const endMinutes = (tValues.end - endHour * 60 * 60) / 60;
      minutes = minutes.filter((m) => m >= endMinutes);
    }

    return minutes;
  };

  return (
    <Modal show={show} onHide={close}>
      <Formik
        initialValues={{
          reminder:
            field.value.find((t) => t.id === taskId)?.reminder === 0
              ? (values as PlayFabRoutine).start
              : field.value.find((t) => t.id === taskId)?.reminder,
        }}
        validationSchema={Yup.object({
          reminder: Yup.number().required(),
        })}
        onSubmit={(formValues) => {
          if (formValues.reminder) {
            var newTasks = [...field.value];

            var index = newTasks.findIndex((t) => t.id === taskId);

            newTasks[index].reminder = formValues.reminder;

            setFieldValue(props.name, newTasks);

            close();
          }
        }}
      >
        <Form>
          <Modal.Header>
            <Modal.Title>{t("edit.tasks.reminder.label")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TimePickerField
              name="reminder"
              disabledHours={disabledHours}
              disabledMinutes={disabledMinutes}
            />
          </Modal.Body>
          <Modal.Footer>
            <BevelButton
              className="btn btn-primary-alt"
              onClick={close}
              type="button"
            >
              {t("edit.cancel")}
            </BevelButton>
            <BevelButton className="btn btn-primary" type="submit">
              {t("edit.tasks.reminder.submit")}
            </BevelButton>
          </Modal.Footer>
        </Form>
      </Formik>
    </Modal>
  );
};

export default function AlertModal(props: TaskModalProps) {
  return (
    <SuspenseLoader>
      <Component {...props} />
    </SuspenseLoader>
  );
}
