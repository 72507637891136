import { BevelButton } from "components/inputs";
import { Coupon, Price } from "hooks/billing/types";
import React from "react";
import { useTranslation } from "react-i18next";
import GreenCheckMark from "assets/GeneralIcons/green_checkmark.png";
import { useCallback } from "react";

interface ProductCardProps {
  interval: "month" | "year";
  prices: Price[];
  currency: string;
  title: string;
  onClick: (priceId: string) => void;
  coupon?: Coupon;
}

const ProductCard: React.FC<ProductCardProps> = ({ coupon, ...props }) => {
  const { t, i18n } = useTranslation(["settings"]);

  const getYearlyPriceWithoutCoupon = useCallback(() => {
    return props.prices.find(
      (p) => p.interval === "year" && p.currency === props.currency
    );
  }, [props.currency, props.prices]);

  const getYearlyPriceWithCoupon = useCallback(() => {
    const yearly = props.prices.find(
      (p) => p.interval === "year" && p.currency === props.currency
    );

    const yearlyCopy = yearly && { ...yearly };

    if (yearlyCopy && yearlyCopy.unitAmount && coupon && coupon.percent_off) {
      yearlyCopy.unitAmount = Math.round(
        yearlyCopy.unitAmount * ((100 - coupon.percent_off) / 100)
      );
    }

    return yearlyCopy;
  }, [coupon, props.currency, props.prices]);

  const price = useCallback(() => {
    if (props.interval === "year") {
      return getYearlyPriceWithCoupon();
    }

    return props.prices.find(
      (p) => p.interval === props.interval && p.currency === props.currency
    );
  }, [getYearlyPriceWithCoupon, props.currency, props.interval, props.prices]);

  const calculateSavingPercentage = useCallback((): string => {
    const monthly =
      props.prices.find(
        (p) => p.interval === "month" && p.currency === props.currency
      )?.unitAmount ?? 0;

    const yearly = getYearlyPriceWithCoupon()?.unitAmount ?? 0;

    return (((monthly * 12 - yearly) / (monthly * 12)) * 100).toFixed(0);
  }, [getYearlyPriceWithCoupon, props.currency, props.prices]);

  return (
    <div className={"price-card price-card-" + props.interval}>
      <div className={"price-card-inner price-card-inner-" + props.interval}>
        <h2>{props.title}</h2>
        <div className="price">
          {t(
            "settings:billing.amount." +
              (props.interval === "year" ? "yearly" : "monthly"),
            {
              amount: (
                (price()?.unitAmount ?? 0) /
                100 /
                (props.interval === "year" ? 12 : 1)
              ).toLocaleString(i18n.language, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }),
            }
          )}
        </div>
        <div className="text-center mb-2">
          {props.interval === "year"
            ? t("settings:billing.billing.yearly", {
                amount: ((price()?.unitAmount ?? 0) / 100).toLocaleString(
                  i18n.language
                ),
              })
            : t("settings:billing.billing.monthly")}
        </div>
        {coupon ? (
          <div className="d-flex justify-content-center align-items-center referral-coupon-display">
            <span className="referral-coupon-display__amount">
              {t("settings:billing.amount.yearlyPerYear", {
                amount: (
                  (getYearlyPriceWithoutCoupon()?.unitAmount ?? 0) / 100
                ).toLocaleString(i18n.language, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }),
              })}
            </span>
            <div className="referral-coupon-display__coupon">
              <i className="icon-tag"></i>
              {coupon.name}
            </div>
          </div>
        ) : null}
        <div className="my-3 d-flex flex-column">
          <p className="mb-3 marketing-title">
            {t("billing.marketing.title")} :
          </p>

          <div className="mx-auto">
            <div className="d-flex">
              <div className="price-card-footer-checkmark-img">
                <img src={GreenCheckMark} alt="Checkmark" />
              </div>
              <p>{t("billing.marketing.point.one")}</p>
            </div>

            <div className="d-flex">
              <div className="price-card-footer-checkmark-img">
                <img src={GreenCheckMark} alt="Checkmark" />
              </div>
              <p>{t("billing.marketing.point.two")}</p>
            </div>

            <div className="d-flex">
              <div className="price-card-footer-checkmark-img">
                <img src={GreenCheckMark} alt="Checkmark" />
              </div>
              <p>{t("billing.marketing.point.three")}</p>
            </div>
          </div>
        </div>
        <BevelButton
          className="btn btn-primary"
          onClick={() => {
            const p = price();
            p && props.onClick(p.id);
          }}
        >
          {t("billing.buy")}
        </BevelButton>
      </div>
      <div className="deal-tag">
        <p>{t("billing.save")}</p>
        <p className="value">{calculateSavingPercentage()}%</p>
      </div>
    </div>
  );
};

export default ProductCard;
