import React, { useEffect, useState } from "react";
import { SuspenseLoader } from "components/graphics";
import useStripe from "hooks/billing/useStripe";
import { useStripe as useStripeHook } from "@stripe/react-stripe-js";
import { Link, useLocation } from "react-router-dom";
import * as API from "services/api/kairosApi";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getLocalCurrency, LocalizationInfo } from "services/Location";
import queryString from "query-string";
import { useAuth } from "@neurosolutionsgroup/authentication";
import { Loader } from "@neurosolutionsgroup/components";
import Products from "./Products";
import { store } from "react-notifications-component";
import { dangerNotification } from "components/notifications";

const Component = () => {
  const { t } = useTranslation(["settings", "billing"]);
  const [localization, setLocalization] = useState<
    LocalizationInfo | undefined
  >(undefined);
  const [loading, setLoading] = useState(false);
  const [attempted, setAttempted] = useState(false);

  const {
    actions: { getAuthentication },
  } = useAuth();

  const urlParams = useLocation().search;
  let params = queryString.parse(urlParams);

  const {
    selectors: { prices },
    actions: { getPrices },
  } = useStripe();

  const stripe = useStripeHook();

  useEffect(() => {
    getPrices();
    getLocalCurrency().then((response) => {
      setLocalization(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      prices.length > 0 &&
      localization &&
      params.autoCheckout &&
      params.product &&
      !attempted
    ) {
      setAttempted(true);
      setLoading(true);

      const product = prices.find(
        (p) =>
          p.interval === params.product && p.currency === localization.currency
      );

      if (product) {
        API.Account.Stripe.createCheckoutSession(
          product.id,
          getAuthentication().sessionTicket,
          localization,
          params.promoCode?.toString()
        )
          .then(async (response) => {
            await stripe?.redirectToCheckout({
              sessionId: response.sessionId,
            });
          })
          .catch((error) => {
            store.addNotification(
              dangerNotification(
                t("forms:errors.title"),
                t("forms:errors.general"),
                false
              )
            );
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prices, localization]);

  return (
    <>
      {prices.length > 0 && localization && (
        <>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Row className="mt-3">
                <Col md={12}>
                  <h2>{t("billing:products.title")}</h2>
                </Col>
              </Row>

              <Products />

              <div className="mt-5">
                <p>
                  <Link to="/app/promocode">
                    {t("billing.clinicaltriallink")}
                  </Link>
                </p>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default function Billing() {
  return (
    <SuspenseLoader>
      <Component />
    </SuspenseLoader>
  );
}
