import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { PlayFabRoutine, Task } from "models/Types";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import { TextInput } from "components/forms";
import "rc-time-picker/assets/index.css";
import TimePickerField from "components/forms/TimePickerField";
import DayPickerField from "components/forms/DayPickerField";
import TaskEditor from "./TaskEditor";
import ChildPickerField from "components/forms/ChildPickerField";

import { BevelButton } from "components/inputs";
import { SuspenseLoader } from "components/graphics";
import { v4 } from "uuid";
import { Link, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

//  Notifications.
import { store } from "react-notifications-component";
import {
  dangerNotification,
  successNotification,
} from "components/notifications/notification";
import useRoutines from "hooks/data/useRoutines";
import useTasks from "hooks/data/useTasks";
import useUsers from "hooks/data/useUsers";

const Component = () => {
  //  HOOKS //
  const { t } = useTranslation(["routines", "forms"]);

  //  State.
  const [isLoading, setIsLoading] = useState(false);

  const {
    selectors: { usersById },
  } = useUsers();

  const {
    selectors: { routinesById },
    actions: { updateRoutine },
  } = useRoutines();

  const {
    selectors: { tasksById },
  } = useTasks();

  var { routineId } = useParams() as any;

  var initialRoutine: PlayFabRoutine;

  const emptyRoutine: PlayFabRoutine = {
    id: "",
    name: "",
    alert: 0,
    days: 0,
    deleted: null,
    disabled: null,
    inactivePeriods: [],
    lastDisabledDate: null,
    start: 36000,
    end: 39600,
    tasks: [],
    users: [],
    version: 0,
  };

  if (routineId) {
    var routineTasks: Task[] = [];

    routinesById[routineId].tasks.forEach((t) => {
      routineTasks.push({ ...tasksById[t] });
    });

    initialRoutine = {
      ...routinesById[routineId],
      tasks: [...routineTasks],
    };
  } else {
    initialRoutine = emptyRoutine;
  }

  return (
    <Formik
      initialValues={initialRoutine}
      enableReinitialize={true}
      validationSchema={Yup.object({
        name: Yup.string()
          .trim()
          .min(1, t("edit.name.errors.min"))
          .max(50, t("edit.name.errors.max"))
          .required(t("forms:errors.required")),
        alert: Yup.number().required(t("forms:errors.required")),
        start: Yup.number()
          .test("max", t("edit.start.errors.end"), function (value) {
            return value ? this.parent.end > value : false;
          })
          .required(t("forms:errors.required")),
        end: Yup.number()
          .test("max", t("edit.end.errors.start"), function (value) {
            return value ? this.parent.start < value : false;
          })
          .required(t("forms:errors.required")),
        days: Yup.number().min(1, t("edit.days.errors.min")),
        users: Yup.array().min(1, t("edit.children.errors.min")),
        tasks: Yup.array()
          .compact((t) => t.deleted)
          .min(1, t("edit.tasks.errors.min")),
      })}
      onSubmit={async (values) => {
        setIsLoading(true);

        if (!routineId) {
          values.id = "ROUT_" + v4();
        }

        console.log(JSON.stringify(values));

        if (!values.deleted) {
          values.deleted = null;
        }

        if (!values.disabled) {
          values.disabled = null;
        }

        if (!values.lastDisabledDate) {
          values.lastDisabledDate = null;
        }

        if (!values.inactivePeriods) {
          values.inactivePeriods = [];
        }

        try {
          await updateRoutine(values);

          store.addNotification(
            successNotification("Success", "Success", true)
          );
        } catch (err) {
          store.addNotification(
            dangerNotification(
              t("forms:errors.title"),
              t("forms:errors.general") +
                ": " +
                t("forms:errors." + err.message),
              false
            )
          );
        } finally {
          setIsLoading(false);
        }
      }}
    >
      <Form>
        <div className="d-flex my-2">
          <Link to="/app/routines">
            <BevelButton className="btn btn-primary">
              <i className="icon-backarrow"></i>
            </BevelButton>
          </Link>
          <div className="ml-auto">
            <BevelButton
              className="btn btn-primary"
              type="submit"
              disabled={isLoading}
            >
              {t("edit.submit")}
            </BevelButton>
          </div>
        </div>
        <Row>
          <Col md={8}>
            <div className="form-group">
              <TextInput label={t("edit.name.label")} name="name" type="text" />
            </div>
          </Col>
          <Col md={4}>
            <div className="form-group h-100">
              <DayPickerField label={t("edit.days.label")} name="days" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="form-group">
              <TimePickerField label={t("edit.start.label")} name="start" />
            </div>
          </Col>
          <Col md={6}>
            <div className="form-group">
              <TimePickerField label={t("edit.end.label")} name="end" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <div className="form-group">
              <ChildPickerField
                label={t("edit.children.label")}
                name="users"
                users={usersById}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="form-group">
              <TaskEditor label={t("edit.tasks.label")} name="tasks" />
            </div>
          </Col>
        </Row>
        <div className="form-group d-flex">
          <div className="ml-auto">
            <BevelButton
              className="btn btn-primary"
              type="submit"
              disabled={isLoading}
            >
              {t("edit.submit")}
            </BevelButton>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default function RoutineForm() {
  return (
    <SuspenseLoader>
      <Component />
    </SuspenseLoader>
  );
}
