import axios from "axios";

import { TaskInstance, User } from "models/Types";
import { PlayFabTaskHistory } from "models/Types";
import { executeCloudScript } from "../CloudScript";

const TITLE_ID = 18652;

//  API URI.
const PLAYFAB_API_BASE = "https://" + TITLE_ID + ".playfabapi.com/";

type confirmTasksType = {
  userId: string;
  history: TaskInstance[];
};

async function confirmTasks(
  userId: string,
  version: number,
  tasks: TaskInstance[],
  sessionTicket: string
) {
  //  Get up to date task history and version.
  const FUNCTION_LOADUSERS = "loadUsers";

  return executeCloudScript(FUNCTION_LOADUSERS, {}, sessionTicket).then(
    (data) => {
      //  Get user history.
      var taskHistory = data.users.find((u: User) => u.id === userId)
        .taskHistory;

      //  Swap for updated tasks.
      taskHistory.history = tasks;

      //  Update task hisotry (status null => 0/1).
      return updatetaskHistory(userId, taskHistory, sessionTicket).then(
        (response) => {
          //  Confirm tasks.
          const FUNCTION_NAME = "confirmTasks";

          const data = {
            userId: userId,
            version: response.version,
            taskHistoryList: tasks,
          };

          return executeCloudScript(FUNCTION_NAME, data, sessionTicket).then(
            (response) => {
              return {
                userId: userId,
                history: response.history,
              } as confirmTasksType;
            }
          );
        }
      );
    }
  );
}

async function updatetaskHistory(
  userId: string,
  taskHistory: PlayFabTaskHistory[],
  sessionTicket: string
) {
  const FUNCTION_NAME = "updateUserTaskHistory";
  const ENDPOINT = "Client/ExecuteCloudScript";

  const data = {
    FunctionName: FUNCTION_NAME,
    FunctionParameter: {
      userId: userId,
      taskHistory: taskHistory,
    },
  };

  const headers = {
    "X-Authorization": sessionTicket,
  };

  return axios
    .post(PLAYFAB_API_BASE + ENDPOINT, data, {
      headers: headers,
    })
    .then((response) => {
      if (response.data.data.Error) {
        return Promise.reject("5000");
      }

      return response.data.data.FunctionResult.data;
    });
}

export type GetMissingHistoriesResponse = {
  version: number;
  history: TaskInstance[];
};

async function getMissingHistories(
  userId: string,
  sessionTicket: string
): Promise<GetMissingHistoriesResponse> {
  const FUNCTION_GETMISSINGHISTORIES = "getHistories";

  let deviceUtcOffsetMin = -new Date().getTimezoneOffset();

  return executeCloudScript(
    FUNCTION_GETMISSINGHISTORIES,
    {
      userId,
      deviceUtcOffsetMin,
    },
    sessionTicket
  ).then((response) => {
    return response as GetMissingHistoriesResponse;
  });
}

export { confirmTasks, getMissingHistories };
