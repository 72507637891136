import axios from "axios";

async function executeCloudScript(
  functionName: string,
  functionParameter: any,
  sessionTicket: string
) {
  const ENDPOINT = "https://18652.playfabapi.com/Client/ExecuteCloudScript";

  const data = {
    FunctionName: functionName,
    FunctionParameter: functionParameter,
  };

  const headers = {
    "X-Authorization": sessionTicket,
  };

  return axios
    .post(ENDPOINT, data, {
      headers: headers,
    })
    .then((response) => {
      if (response.data.code === 200) {
        return response.data.data.FunctionResult?.data
          ? response.data.data.FunctionResult.data
          : response.data.data.FunctionResult;
      } else {
        return Promise.reject(response.data);
      }
    });
}

export { executeCloudScript };
