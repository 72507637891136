import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { Row, Col, Tab, Nav } from "react-bootstrap";
import { PlainCard } from "components/layout/cards";
import { SuspenseLoader } from "components/graphics";
import { ProtectedFunction } from "components/navigation";

import Validation from "./validation/Validation";
import NoChildPlaceholder from "./NoChildPlaceholder";
import ChildPicker from "components/inputs/ChildPicker/ChildPicker";
import Today from "./graphics/Today/Today";
import AppDataContext from "hooks/data/AppDataContext";
import useUsers from "hooks/data/useUsers";
import useUserProfile from "hooks/account/useUserProfile";
import { Loader } from "@neurosolutionsgroup/components";
import { ExpiredSubscription } from "components/alerts";

const Component = () => {
  //  Hooks  //
  //  Localization.
  const { t } = useTranslation(["dashboard", "base"]);

  const {
    selectors: { userProfile },
  } = useUserProfile();

  const { dataFetched } = useContext(AppDataContext);

  const {
    selectors: { userIds, usersById, selectedUserId },
    actions: { setSelectedUserId },
  } = useUsers();

  const changeUserIdHandler = (id: string) => {
    setSelectedUserId(id);
  };

  return (
    <div className="py-3">
      {!dataFetched || !selectedUserId ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          {/* Test for no children. */}
          {userIds && userIds.length === 0 ? (
            <Row>
              <Col>
                <NoChildPlaceholder />
              </Col>
            </Row>
          ) : (
            <>
              {!userProfile.isSubscribed && !userProfile.isFreeTrial && (
                <ExpiredSubscription />
              )}
              <Row>
                <Col lg={5}>
                  <div>
                    <Row className="grid-dashboard-top mb-3">
                      {userIds.length > 0 ? (
                        <Col>
                          <div className="">
                            <ChildPicker
                              userDefinitions={usersById}
                              userId={selectedUserId}
                              setUserId={changeUserIdHandler}
                            />
                          </div>
                        </Col>
                      ) : null}
                    </Row>
                    <Row>
                      <Col>
                        <ProtectedFunction
                          isSubscribed={userProfile.isSubscribed}
                          isFreeTrial={userProfile.isFreeTrial}
                          function={t("functions.validation")}
                        >
                          <PlainCard className="p-3 dashboard-section-validation">
                            <Validation />
                          </PlainCard>
                        </ProtectedFunction>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col lg={7}>
                  <div>
                    <Tab.Container defaultActiveKey="today">
                      <Row className="grid-dashboard-top mb-3">
                        <Col>
                          <Nav
                            variant="pills"
                            className="justify-content-center"
                          >
                            <Nav.Item className="px-1">
                              <Nav.Link eventKey="today">
                                {t("graphics.today.title")}
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="px-1">
                              <Nav.Link eventKey="week">
                                {t("graphics.week.title")}
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="px-1">
                              <Nav.Link eventKey="progress">
                                {t("graphics.progress.title")}
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Tab.Content>
                            <Tab.Pane eventKey="today">
                              <Today />
                            </Tab.Pane>
                            <Tab.Pane eventKey="week">
                              <PlainCard className="p-3">
                                <h2 className="text-left">
                                  {t("graphics.week.title")}
                                </h2>
                                <p>Coming soon...</p>
                              </PlainCard>
                            </Tab.Pane>
                            <Tab.Pane eventKey="progress">
                              <PlainCard className="p-3">
                                <h2 className="text-left">
                                  {t("graphics.progress.title")}
                                </h2>
                                <p>Coming soon...</p>
                              </PlainCard>
                            </Tab.Pane>
                          </Tab.Content>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default function Dashboard() {
  return (
    <SuspenseLoader>
      <Component />
    </SuspenseLoader>
  );
}
