import React, { FunctionComponent } from "react";
import { Form, Formik, useField, useFormikContext } from "formik";
import * as Yup from "yup";

import { Task } from "models/Types";

import { Modal } from "react-bootstrap";

import { BevelButton } from "components/inputs";
import { useTranslation } from "react-i18next";
import { TaskModalProps } from ".";
import TaskIconPickerField from "components/forms/TaskIconPickerField";

const IconModal: FunctionComponent<TaskModalProps> = ({
  show,
  close,
  taskId,
  ...props
}) => {
  const { t } = useTranslation("routines");

  const [field] = useField<Task[]>(props);

  const { setFieldValue } = useFormikContext();

  return (
    <Modal show={show} onHide={close}>
      <Formik
        initialValues={{
          icon: field.value.find((t) => t.id === taskId)?.icon,
        }}
        validationSchema={Yup.object({
          icon: Yup.number().required(),
        })}
        onSubmit={(values) => {
          if (values.icon) {
            var newTasks = [...field.value];

            var index = newTasks.findIndex((t) => t.id === taskId);

            newTasks[index].icon = values.icon;

            setFieldValue(field.name, newTasks);

            close();
          }
        }}
      >
        <Form>
          <Modal.Header>
            <Modal.Title>{t("edit.tasks.icon.label")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TaskIconPickerField name="icon" />
          </Modal.Body>
          <Modal.Footer>
            <BevelButton
              className="btn btn-primary-alt"
              onClick={close}
              type="button"
            >
              {t("edit.cancel")}
            </BevelButton>
            <BevelButton className="btn btn-primary" type="submit">
              {t("edit.tasks.icon.submit")}
            </BevelButton>
          </Modal.Footer>
        </Form>
      </Formik>
    </Modal>
  );
};

export default IconModal;
