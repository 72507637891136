import React from "react";
import { useTranslation } from "react-i18next";
import SuspenseLoader from "./SuspenseLoader";

function Component() {
  const { t } = useTranslation(["images"]);

  return (
    <div className="logo">
      <img
        alt="Kairos Logo"
        src={require("assets/kairoslogos/" + t("logo") + ".png").default}
      />
    </div>
  );
}

export default function RegistrationMenu() {
  return (
    <SuspenseLoader>
      <Component />
    </SuspenseLoader>
  );
}
