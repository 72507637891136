import React from "react";
import { useHistory } from "react-router-dom";
import CardPage from "components/layout/pages/CardPage";
import LogoCard from "components/layout/cards/LogoCard";
import { SuspenseLoader } from "components/graphics";
import TrackingAPI from "services/tracking";
import { dangerNotification } from "components/notifications";
import { store } from "react-notifications-component";
import { useTranslation } from "react-i18next";
import {
  CompleteRegistrationPage,
  AuthError,
} from "@neurosolutionsgroup/authentication";

const Component = () => {
  const history = useHistory();
  const { t } = useTranslation(["account", "forms"]);
  const onSuccess = (firstName: string, lastName: string) => {
    TrackingAPI.Account.userRegistrationCompleted(firstName, lastName).catch(
      () => {
        // Error non-blocking.
        console.log("[Registration] Tracking failed");
      }
    );
    history.push("/callbacks/links");
  };
  const onFailure = (err: AuthError) => {
    store.addNotification(
      dangerNotification(
        t("forms:errors.title"),
        t("forms:errors.general") + ": " + t("forms:errors." + err.code),
        false
      )
    );
  };

  return (
    <CardPage>
      <LogoCard className="bg-beige">
        <CompleteRegistrationPage onSuccess={onSuccess} onFailure={onFailure} />
      </LogoCard>
    </CardPage>
  );
};

export default function CompleteRegistration() {
  return (
    <SuspenseLoader>
      <Component />
    </SuspenseLoader>
  );
}
