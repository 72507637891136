import { HookResult } from "hooks/HookResult";
import { User } from "models/Types";
import { useContext } from "react";
import UserContext, { UsersById } from "./UserContext";
import * as PlayFabAPI from "services/api/playFabApi";
import { NormalizrResult, parsePlayFabUserData } from "./Tools";
import { normalize } from "normalizr";
import { user } from "models/Schema";
import { useAuth } from "@neurosolutionsgroup/authentication";

export interface useUsersSelectors {
  userIds: string[];
  usersById: UsersById;
  selectedUserId: string;
}

export interface useUsersActions {
  updateUser: (user: User) => Promise<void>;
  addUser: (user: User) => Promise<void>;
  setSelectedUserId: (userId: string) => void;
}

const useUsers = (): HookResult<useUsersSelectors, useUsersActions> => {
  const {
    userIds,
    setUserIds,
    usersById,
    setUsersById,
    selectedUserId,
    setSelectedUserId,
  } = useContext(UserContext);

  const {
    actions: { getAuthentication },
  } = useAuth();

  const updateUser = async (updatedUser: User) => {
    return PlayFabAPI.Users.updateUser(
      updatedUser,
      getAuthentication().sessionTicket
    ).then((response) => {
      const users: User[] = parsePlayFabUserData(response.users);

      var data = normalize(
        { users: users },
        {
          users: [user],
        }
      ) as NormalizrResult;

      setUserIds(data.result.users);
      setUsersById(data.entities.users);
    });
  };

  const addUser = async (newUser: User) => {
    return PlayFabAPI.Users.addUser(
      newUser,
      getAuthentication().sessionTicket
    ).then((response) => {
      const users: User[] = parsePlayFabUserData(response.users);

      var data = normalize(
        { users: users },
        {
          users: [user],
        }
      ) as NormalizrResult;

      //  Update state.
      setUserIds(data.result.users);
      setUsersById(data.entities.users);
    });
  };

  return {
    selectors: {
      userIds,
      usersById,
      selectedUserId,
    },
    actions: {
      updateUser,
      addUser,
      setSelectedUserId,
    },
  };
};

export default useUsers;
