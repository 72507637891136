const check = () => {
  return window.ga ? true : false;
};

const trackRegistration = () => {
  track("AccountCreated");
};

const trackRegistrationCompleted = () => {
  track("AccountCreationCompleted");
};

const trackSMSRequested = () => {
  track("SMSRequested");
};

const trackAppleLinkClicked = () => {
  track("AppleLinkClicked");
};

const trackPlayStoreLinkClicked = () => {
  track("PlayStoreLinkClicked");
};

const track = (string) => {
  if (window.ga) {
    window.ga("send", "WebApp", string);
  }
};

const GA = {
  check,
  trackRegistration,
  trackRegistrationCompleted,
  trackSMSRequested,
  trackAppleLinkClicked,
  trackPlayStoreLinkClicked,
};

export default GA;
