import { PlainCard } from "components/layout/cards";
import React from "react";
import { Col, Row } from "react-bootstrap";
import RoutineCard from "./RoutineCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ButtonGroup from "./CustomButtonGroup";
import { useTranslation } from "react-i18next";
import useRoutines from "hooks/data/useRoutines";
import useUsers from "hooks/data/useUsers";

const Component = () => {
  //  HOOKS //
  const { t } = useTranslation("dashboard");

  const {
    selectors: { selectedUserId },
  } = useUsers();

  const {
    selectors: { routinesById },
  } = useRoutines();

  const responsive = {
    s: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
    m: {
      breakpoint: { max: 1399, min: 769 },
      items: 2,
    },
    l: {
      breakpoint: { max: 4000, min: 1400 },
      items: 2,
    },
  };

  return (
    <PlainCard className="p-3">
      <Row>
        <Col>
          <h2 className="text-left">{t("graphics.today.title")}</h2>
        </Col>
      </Row>
      <Row>
        {routinesById === undefined ||
        Object.keys(routinesById).filter(
          (key) =>
            !routinesById[key].deleted &&
            !routinesById[key].disabled &&
            routinesById[key].users.includes(selectedUserId)
        ).length === 0 ? (
          <Col>{t("graphics.today.no-routines")}</Col>
        ) : (
          <Col>
            <Carousel
              responsive={responsive}
              className="w-100"
              arrows={false}
              customButtonGroup={
                <ButtonGroup text={t("graphics.today.routines-link")} />
              }
              swipeable={true}
            >
              {Object.keys(routinesById)
                .filter(
                  (key) =>
                    !routinesById[key].deleted &&
                    !routinesById[key].disabled &&
                    routinesById[key].users.includes(selectedUserId)
                )
                .sort((a, b) => routinesById[a].start - routinesById[b].start)
                .map((key, index) => {
                  return (
                    <div key={key} className="mx-4">
                      <RoutineCard
                        key={key}
                        routine={routinesById[key]}
                        index={index}
                      />
                    </div>
                  );
                })}
            </Carousel>
          </Col>
        )}
      </Row>
    </PlainCard>
  );
};

export default function Today() {
  return <Component />;
}
