import React, { FunctionComponent } from "react";
import { Card } from "react-bootstrap";

interface PlainCardProps extends React.HTMLAttributes<HTMLElement> {
  outerClasses?: string;
}

const PlainCard: FunctionComponent<PlainCardProps> = ({
  className,
  outerClasses,
  ...props
}) => {
  return (
    <div className={"card-wrap " + outerClasses}>
      <Card className={"h-100 " + className} {...props}>
        {props.children}
      </Card>
    </div>
  );
};

export default PlainCard;
