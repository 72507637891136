import React, { FunctionComponent } from "react";
import { Form, Formik, useField, useFormikContext } from "formik";
import * as Yup from "yup";

import { Task } from "models/Types";

import { Modal } from "react-bootstrap";

import { SuspenseLoader } from "components/graphics";
import { TextInput } from "components/forms";
import { BevelButton } from "components/inputs";
import { useTranslation } from "react-i18next";
import { TaskModalProps } from ".";

const Component: FunctionComponent<TaskModalProps> = ({
  show,
  close,
  taskId,
  ...props
}) => {
  const { t } = useTranslation("routines");

  const [field] = useField<Task[]>(props);

  const { setFieldValue } = useFormikContext();

  return (
    <Modal show={show} onHide={close}>
      <Formik
        initialValues={{
          name: field.value.find((t) => t.id === taskId)?.name ?? "",
        }}
        validationSchema={Yup.object({
          name: Yup.string()
            .max(50, t("edit.name.errors.max"))
            .min(1, t("edit.name.errors.min"))
            .required(),
        })}
        onSubmit={(values) => {
          var newTasks = [...field.value];

          var index = newTasks.findIndex((t) => t.id === taskId);

          newTasks[index].name = values.name;

          setFieldValue(field.name, newTasks);

          close();
        }}
      >
        <Form>
          <Modal.Header>
            <Modal.Title>{t("edit.tasks.name.label")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TextInput name="name" type="text" hideLabel={true} />
          </Modal.Body>
          <Modal.Footer>
            <BevelButton
              className="btn btn-primary-alt"
              onClick={close}
              type="button"
            >
              {t("edit.cancel")}
            </BevelButton>
            <BevelButton className="btn btn-primary" type="submit">
              {t("edit.tasks.name.submit")}
            </BevelButton>
          </Modal.Footer>
        </Form>
      </Formik>
    </Modal>
  );
};

export default function NameModal(props: TaskModalProps) {
  return (
    <SuspenseLoader>
      <Component {...props} />
    </SuspenseLoader>
  );
}
