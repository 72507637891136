import { useAuth } from "@neurosolutionsgroup/authentication";
import { HookResult } from "hooks/HookResult";
import * as API from "services/api/kairosApi";
import * as PlayFabAPI from "services/api/playFabApi";

interface UsePromoCodesActions {
  redeemPromoCode: (promoCode: string) => Promise<boolean>;
  redeemExtendedTrial: () => Promise<boolean>;
}

const usePromoCodes = (): HookResult<{}, UsePromoCodesActions> => {
  const {
    actions: { getAuthentication },
  } = useAuth();

  const KNOWN_ERRORS = ["2200", "2201", "2202"];

  const redeemPromoCode = async (promoCode: string): Promise<boolean> => {
    return await API.Account.Billing.postPromoCode(
      promoCode,
      getAuthentication().sessionTicket
    )
      .then(() => {
        return true;
      })
      .catch((err) => {
        const errorMessage = err as string;

        if (KNOWN_ERRORS.includes(errorMessage.toString())) {
          return Promise.reject(new Error(errorMessage));
        } else {
          return Promise.reject(new Error("unknown"));
        }
      });
  };

  const redeemExtendedTrial = async (): Promise<boolean> => {
    return await PlayFabAPI.Account.addExtendedFreeTrial(
      getAuthentication().sessionTicket
    )
      .then(() => true)
      .catch(() => {
        return Promise.reject(new Error("unknown"));
      });
  };

  return {
    selectors: {},
    actions: {
      redeemPromoCode,
      redeemExtendedTrial,
    },
  };
};

export default usePromoCodes;
