import React, { FunctionComponent } from "react";

import { User } from "models/Types";
import { Badge } from "react-bootstrap";
import Dropdown from "../Dropdown/Dropdown";

interface ChildPickerProps {
  userDefinitions: { [key: string]: User };
  userId: string;
  setUserId: (id: string) => void;
}

const ChildPicker: FunctionComponent<ChildPickerProps> = ({
  userDefinitions,
  userId,
  setUserId,
  ...props
}) => {
  return (
    <>
      <Dropdown
        className="child-picker w-100"
        buttonClasses="btn btn-primary w-100 d-flex align-items-center"
        toggle={
          <>
            <img
              alt=""
              src={
                require("assets/ProfileIcons/pi_" +
                  userDefinitions[userId].icon +
                  ".png").default
              }
              className="profile-icon mr-3"
            />
            <b className="mr-2">{userDefinitions[userId].name}</b>
            <Badge variant="secondary" className="ml-auto mt-1">
              {userDefinitions[userId].tasksToValidate.length}
            </Badge>
          </>
        }
        links={Object.keys(userDefinitions).map((user) => {
          return {
            onClick: () => setUserId(user),
            text: (
              <>
                <img
                  alt=""
                  src={
                    require("assets/ProfileIcons/pi_" +
                      userDefinitions[user].icon +
                      ".png").default
                  }
                  className="profile-icon mr-3"
                />
                <b className="mr-2">{userDefinitions[user].name}</b>
                <Badge variant="secondary" className="ml-auto">
                  {userDefinitions[user].tasksToValidate.length}
                </Badge>
              </>
            ),
          };
        })}
      />
    </>
  );
};

export default ChildPicker;
