export enum Currency {
  CAD = "cad",
  USD = "usd",
  EUR = "eur",
}

export type LocalizationInfo = {
  currency: Currency;
  country: string;
  countryCode: string;
  continent: string;
  region: string;
  ipAddress: string;
};

//  Detect location and corresponding currency using api call.
export const getLocalCurrency = async (): Promise<LocalizationInfo> => {
  return fetch("https://extreme-ip-lookup.com/json/")
    .then((res) => res.json())
    .then((response) => {
      var currency: Currency;

      if (response.continent === "Europe") {
        currency = Currency.EUR;
      } else if (response.country === "Canada") {
        currency = Currency.CAD;
      } else {
        currency = Currency.USD;
      }

      return {
        currency: currency,
        country: response.country,
        countryCode: response.countryCode,
        continent: response.continent,
        region: response.region,
        ipAddress: response.query,
      };
    })
    .catch((err) => {
      return {
        currency: Currency.USD,
        country: "",
        countryCode: "",
        continent: "",
        region: "",
        ipAddress: "",
      };
    });
};
