import { HookResult } from "hooks/HookResult";
import { TaskInstance } from "models/Types";
import { useContext } from "react";
import TaskContext, { TasksById } from "./TaskContext";
import * as PlayFabAPI from "services/api/playFabApi";
import UserContext from "./UserContext";
import { useAuth } from "@neurosolutionsgroup/authentication";

export interface UseTasksSelectors {
  tasksById: TasksById;
}

export interface UseTasksActions {
  validateTasks: (args: ValidateTasksArgs) => Promise<void>;
  setTaskStatus: (args: SetTaskStatusArgs) => void;
  getMissingHistories: (userId: string) => Promise<void>;
}

type ValidateTasksArgs = {
  userId: string;
  version: number;
  tasks: TaskInstance[];
};

type SetTaskStatusArgs = {
  userId: string;
  taskIndex: number;
  status: boolean;
};

const useTasks = (): HookResult<UseTasksSelectors, UseTasksActions> => {
  const { tasksById } = useContext(TaskContext);
  const { setUsersById } = useContext(UserContext);

  const {
    actions: { getAuthentication },
  } = useAuth();

  const setTaskStatus = (args: SetTaskStatusArgs) => {
    setUsersById((prevState) => {
      return {
        ...prevState,
        [args.userId]: {
          ...prevState[args.userId],
          tasksToValidate: prevState[args.userId].tasksToValidate.map(
            (task, index) => {
              if (index === args.taskIndex) {
                return {
                  ...task,
                  status: args.status,
                };
              } else {
                return task;
              }
            }
          ),
        },
      };
    });
  };

  const validateTasks = async (args: ValidateTasksArgs): Promise<void> => {
    return PlayFabAPI.Routine.Tasks.confirmTasks(
      args.userId,
      args.version,
      args.tasks,
      getAuthentication().sessionTicket
    ).then((response) => {
      var filteredTasks: TaskInstance[] = [];

      response.history
        .filter((t) => (t !== null ? t.confirmTime === null : false))
        .forEach((task) => {
          filteredTasks.push(task);
        });

      setUsersById((prevState) => {
        return {
          ...prevState,
          [response.userId]: {
            ...prevState[response.userId],
            tasksToValidate: filteredTasks,
          },
        };
      });
    });
  };

  const getMissingHistories = async (userId: string) => {
    return PlayFabAPI.Routine.Tasks.getMissingHistories(
      userId,
      getAuthentication().sessionTicket
    ).then((response) => {
      if (!response || !response.history) {
        return;
      }

      var filteredTasks: TaskInstance[] = [];

      response.history
        .filter((t) => (t !== null ? t.confirmTime === null : false))
        .forEach((task) => {
          filteredTasks.push(task);
        });

      setUsersById((prevState) => {
        return {
          ...prevState,
          [userId]: {
            ...prevState[userId],
            tasksToValidate: filteredTasks,
          },
        };
      });
    });
  };

  return {
    selectors: {
      tasksById,
    },
    actions: {
      validateTasks,
      setTaskStatus,
      getMissingHistories,
    },
  };
};

export default useTasks;
