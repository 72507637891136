import React, { FunctionComponent } from "react";

import { Modal } from "react-bootstrap";

import { SuspenseLoader } from "components/graphics";
import { BevelButton } from "components/inputs";
import { useTranslation } from "react-i18next";
import { RoutineModalProps } from ".";

//  Notifications.
import { store } from "react-notifications-component";
import { dangerNotification } from "components/notifications/notification";
import useRoutines from "hooks/data/useRoutines";

const Component: FunctionComponent<RoutineModalProps> = ({
  show,
  close,
  routine,
  ...props
}) => {
  const { t } = useTranslation("routines");

  const {
    actions: { updateRoutine },
  } = useRoutines();

  const removeRoutine = async () => {
    try {
      if (routine) await updateRoutine(routine);
    } catch (err) {
      store.addNotification(
        dangerNotification(
          t("forms:errors.title"),
          t("forms:errors.general") + ": " + t("forms:errors." + err.message),
          false
        )
      );
    } finally {
      close();
    }
  };

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header>
        <Modal.Title>{t("edit.delete.label")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t("edit.delete.message")}</p>
      </Modal.Body>
      <Modal.Footer>
        <BevelButton
          className="btn btn-primary-alt"
          onClick={close}
          type="button"
        >
          {t("edit.cancel")}
        </BevelButton>
        <BevelButton
          className="btn btn-primary"
          onClick={removeRoutine}
          type="button"
        >
          {t("edit.delete.submit")}
        </BevelButton>
      </Modal.Footer>
    </Modal>
  );
};

export default function DeleteRoutineModal(props: RoutineModalProps) {
  return (
    <SuspenseLoader>
      <Component {...props} />
    </SuspenseLoader>
  );
}
