import React, { useEffect, useRef } from "react";
import { SuspenseLoader } from "components/graphics";
import { useTranslation } from "react-i18next";

import Task from "./Task";
import { BevelButton, BevelBorderButton } from "components/inputs";
import { TaskInstance } from "models/Types";
import { getTimeString } from "services/DateTime";
import useUsers from "hooks/data/useUsers";
import useTasks from "hooks/data/useTasks";
import useRoutines from "hooks/data/useRoutines";
import { useState } from "react";

import { store } from "react-notifications-component";
import { dangerNotification } from "components/notifications";
import { Loader } from "@neurosolutionsgroup/components";

const Component = () => {
  const date = useRef<number | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  //  Hooks.
  //  Language management.
  const { t } = useTranslation(["validation"]);

  const {
    selectors: { usersById, selectedUserId },
  } = useUsers();
  const {
    selectors: { tasksById },
    actions: { validateTasks, setTaskStatus, getMissingHistories },
  } = useTasks();
  const {
    selectors: { routinesById },
  } = useRoutines();

  // Get missed histories on userId change.
  useEffect(() => {
    setIsLoading(true);

    getMissingHistories(selectedUserId).finally(() => {
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //  Reset date on component load.
  useEffect(() => {
    date.current = null;
  }, []);

  //  Functions.
  //  Has date changed?
  const checkDate = (dueTime: number) => {
    if (date) {
      if (date.current === dueTime) {
        return false;
      }
    }
    return true;
  };

  //  Generate date string from epoch time.
  const getDate = (dueTime: number) => {
    date.current = dueTime;
    var displayDate = new Date(dueTime * 1000);
    return (
      displayDate.getFullYear().toString() +
      "/" +
      displayDate.getMonth().toString() +
      "/" +
      displayDate.getDate().toString()
    );
  };

  const setTaskStatusHandler = (taskIndex: number, status: boolean) => {
    var payload = {
      userId: selectedUserId,
      taskIndex: taskIndex,
      status: status,
    };

    setTaskStatus(payload);
  };

  const switchAllTaskHandler = (status: boolean) => {
    usersById[selectedUserId].tasksToValidate.forEach((task, index) => {
      var payload = {
        userId: selectedUserId,
        taskIndex: index,
        status: status,
      };
      setTaskStatus(payload);
    });
  };

  const validateTasksHandler = () => {
    setIsLoading(true);

    //  Get tasks that have been confirmed.
    const tasks: TaskInstance[] = [];

    usersById[selectedUserId].tasksToValidate
      .filter((t) => t.status != null)
      .forEach((task) => {
        tasks.push(task);
      });

    const version = usersById[selectedUserId].taskListVersion;

    validateTasks({
      userId: selectedUserId,
      version: version,
      tasks: tasks,
    })
      .catch((err) => {
        store.addNotification(
          dangerNotification(
            t("forms:errors.title"),
            t("forms:errors.general") + ": " + t("forms:errors." + err.message),
            true
          )
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div id="validation-top"></div>
          {usersById[selectedUserId].tasksToValidate.length > 0 ? null : (
            <div className="d-flex justify-content-center align-items-center flex-column">
              <h3 className="w-100 ml-3 text-left">
                {t("count", {
                  count: usersById[selectedUserId].tasksToValidate.length,
                })}
              </h3>
              <img
                className="w-100"
                alt="Girl avatar checking watch"
                src={
                  require("assets/avatars/girl/AvatarGirl_checkWatch_nobg.png")
                    .default
                }
              />
              <p className="pl-3 text-center">
                <b>{t("notasks")}</b>
              </p>
            </div>
          )}
          <div className="p-3">
            <div>
              {usersById[selectedUserId].tasksToValidate.length > 0 ? (
                <>
                  <div className="d-flex">
                    <h3 className="text-left">
                      {t("count", {
                        count: usersById[selectedUserId].tasksToValidate.length,
                      })}
                    </h3>
                    <div className="d-flex ml-auto mr-3 task-toggle-switch align-items-center">
                      <BevelBorderButton
                        className="btn btn-primary btn-task-validation btn-task-validation-false selected"
                        onClick={() => switchAllTaskHandler(false)}
                      >
                        <i className="icon-cross"></i>
                      </BevelBorderButton>
                      <BevelBorderButton
                        className="btn btn-primary btn-task-validation btn-task-validation-true ml-2 selected"
                        onClick={() => switchAllTaskHandler(true)}
                      >
                        <i className="icon-tick"></i>
                      </BevelBorderButton>
                    </div>
                  </div>
                </>
              ) : null}
              {usersById[selectedUserId].tasksToValidate.map(
                (task, taskIndex) => (
                  <div key={task.task + "-" + task.dueTime}>
                    {checkDate(task.dueTime) ? (
                      <div className="task-date my-2">
                        <p>
                          {getDate(task.dueTime) +
                            " " +
                            getTimeString(
                              routinesById[tasksById[task.task].routine].start
                            )}{" "}
                          <b>
                            {routinesById[tasksById[task.task].routine].name}
                          </b>
                        </p>
                      </div>
                    ) : null}
                    <div>
                      <Task
                        task={task}
                        index={taskIndex}
                        setTaskStatus={setTaskStatusHandler}
                      />
                    </div>
                  </div>
                )
              )}
            </div>
            {usersById[selectedUserId].tasksToValidate.length > 0 ? (
              <>
                <BevelButton
                  className="btn btn-primary float-right my-2"
                  onClick={() => validateTasksHandler()}
                >
                  {t("validate")}
                </BevelButton>
              </>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};

export default function Validation() {
  return (
    <SuspenseLoader>
      <Component />
    </SuspenseLoader>
  );
}
