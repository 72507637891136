import { normalize } from "normalizr";
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import * as PlayFabAPI from "services/api/playFabApi";
import {
  NormalizrResult,
  parsePlayFabRoutineData,
  parsePlayFabUserData,
} from "./Tools";
import { routine, task, user } from "models/Schema";
import TaskContext from "./TaskContext";
import UserContext from "./UserContext";
import RoutineContext from "./RoutineContext";
import { useAuth } from "@neurosolutionsgroup/authentication";

export interface AppDataContextData {
  dataFetched: boolean;
}

const AppDataContext = React.createContext<AppDataContextData>(null!);

export const AppDataProvider = (props: PropsWithChildren<any>) => {
  const [dataFetched, setDataFetched] = useState(false);

  const { setTasksById } = useContext(TaskContext);
  const { setRoutineIds, setRoutinesById } = useContext(RoutineContext);
  const { setUserIds, setUsersById, setSelectedUserId } = useContext(
    UserContext
  );

  const {
    actions: { getAuthentication },
  } = useAuth();

  useEffect(() => {
    PlayFabAPI.Data.getData(getAuthentication().sessionTicket).then(
      (response) => {
        const rawData = {
          users: response.users?.users
            ? parsePlayFabUserData(response.users.users)
            : [],
          routines: response.routines
            ? parsePlayFabRoutineData(response.routines)
            : [],
        };

        //  Normalize (flatten) data received.
        var data = normalize(rawData, {
          users: [user],
          tasks: [task],
          routines: [routine],
        }) as NormalizrResult;

        setTasksById(data.entities.tasks);

        setRoutineIds(data.result.routines);
        setRoutinesById(data.entities.routines);

        setUserIds(data.result.users);
        setUsersById(data.entities.users);

        if (data.result.users.length > 0) {
          setSelectedUserId(data.result.users[0]);
        }

        setDataFetched(true);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppDataContext.Provider value={{ dataFetched }}>
      {props.children}
    </AppDataContext.Provider>
  );
};

export default AppDataContext;
