import mixpanel from "mixpanel-browser";

export interface Dict {
  [key: string]: any;
}

//  Check if mixpanel initiated and setup if not.
const init = () => {
  if (!mixpanel.people) {
    if (process.env.REACT_APP_MIXPANEL_TOKEN) {
      //mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
      mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {}, "");
    } else {
      return Promise.reject("Missing Mixpanel Token");
    }
  }
};

const identify = (id: string) => {
  init();

  mixpanel.identify(id);
};

const alias = (newId: string, oldId?: string) => {
  init();

  mixpanel.alias(newId);
};

const track = async (event: string, properties?: any): Promise<void> => {
  init();

  mixpanel.track(event, properties);
};

const set = async (properties: Dict) => {
  init();

  mixpanel.people.set(properties);
};

const set_once = async (properties: Dict) => {
  init();

  mixpanel.people.set_once(properties);
};

const reset = () => {
  if (mixpanel.people) {
    mixpanel.reset();
  }
};

const Mixpanel = {
  identify,
  alias,
  track,
  people: {
    set,
    set_once,
  },
  reset,
};

export default Mixpanel;
