import { PlainCard } from "components/layout/cards";
import { FieldConfig, useField, useFormikContext } from "formik";
import { User } from "models/Types";
import React, { FunctionComponent } from "react";

interface ChildPickerProps extends FieldConfig {
  label?: string;
  users: { [key: string]: User };
}

const ChildPickerField: FunctionComponent<ChildPickerProps> = ({
  label,
  users,
  ...props
}) => {
  const [field, meta] = useField<string[]>(props);

  const { setFieldValue } = useFormikContext();

  const toggleField = (key: string) => {
    if (field.value.includes(key)) {
      setFieldValue(
        props.name,
        field.value.filter((u) => u !== key)
      );
    } else {
      setFieldValue(props.name, [...field.value, ...[key]]);
    }
  };

  return (
    <>
      {label ? <label htmlFor={props.name}>{label}</label> : null}
      {Object.keys(users).map((key) => (
        <PlainCard
          className="child-picker-field p-3 mb-2 d-flex flex-row align-items-center"
          outerClasses="child-picker-field-wrapper"
          key={key}
          onClick={() => toggleField(key)}
        >
          <img
            alt={users[key].icon.toString()}
            src={
              require(`assets/ProfileIcons/pi_${users[key].icon}.png`).default
            }
            className="profile-icon mr-3"
          />
          <p>{users[key].name}</p>
          <input
            type="checkbox"
            id={"included-" + key}
            name="method"
            checked={field.value.includes(key)}
            onChange={(e) => {
              if (e.target.checked) {
                setFieldValue(props.name, [...field.value, ...[key]]);
              } else {
                setFieldValue(
                  props.name,
                  field.value.filter((u) => u !== key)
                );
              }
            }}
          />
          <label className="ml-auto" htmlFor={"included-" + key}></label>
        </PlainCard>
      ))}
      {meta.touched && meta.error ? (
        <div className="text-danger">{meta.error}</div>
      ) : null}
    </>
  );
};

export default ChildPickerField;
