import React, { useState } from "react";

import { getTimeString } from "services/DateTime";

import { SuspenseLoader } from "components/graphics";
import DayDisplay from "components/graphics/DayDisplay";
import { PlainCard } from "components/layout/cards";
import { Col, Row } from "react-bootstrap";
import { PlayFabRoutine } from "models/Types";
import RoutineForm from "./RoutineForm";
import { Route, Switch, useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import { BevelButton } from "components/inputs";
import { useTranslation } from "react-i18next";

//  Notifications.
import { store } from "react-notifications-component";
import { dangerNotification } from "components/notifications/notification";
import DeleteRoutineModal from "./DeleteRoutineModal";
import useRoutines from "hooks/data/useRoutines";
import useUsers from "hooks/data/useUsers";
import useTasks from "hooks/data/useTasks";
import { Loader } from "@neurosolutionsgroup/components";

const Component = () => {
  //  HOOKS //
  const { t } = useTranslation("routines");

  const match = useRouteMatch();

  //  State.
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentRoutine, setCurrentRoutine] = useState<PlayFabRoutine | null>(
    null
  );

  const {
    selectors: { usersById, userIds },
  } = useUsers();

  const {
    selectors: { routinesById },
    actions: { updateRoutine },
  } = useRoutines();

  const {
    selectors: { tasksById },
  } = useTasks();

  //  Handlers.
  const checkChangeHandler = async (routineId: string) => {
    setIsLoading(true);
    try {
      //  Denormalize the routine to return to PlayFab.
      var newRoutine: PlayFabRoutine = {
        ...routinesById[routineId],
        disabled: !routinesById[routineId].disabled,
        tasks: routinesById[routineId].tasks.map((task) => {
          return tasksById[task];
        }),
      };

      await updateRoutine(newRoutine);
    } catch (err) {
      store.addNotification(
        dangerNotification(
          t("forms:errors.title"),
          t("forms:errors.general") + ": " + t("forms:errors." + err.message),
          false
        )
      );
    } finally {
      setIsLoading(false);
    }
  };

  const deleteRoutineHandler = (routineId: string) => {
    var routine: PlayFabRoutine = {
      ...routinesById[routineId],
      deleted: true,
      tasks: routinesById[routineId].tasks.map((task) => {
        return tasksById[task];
      }),
    };

    setCurrentRoutine(routine);
    setShowDeleteModal(true);
  };

  const checkExistingUsers = (routineUserIds: string[]): boolean => {
    for (const id of routineUserIds) {
      if (!userIds.includes(id)) {
        return false;
      }
    }

    return true;
  };

  return (
    <>
      <Switch>
        <Route exact path="/app/routines">
          <PlainCard className="p-3">
            {isLoading ? <Loader /> : null}
            <h2 className="text-left">Routines</h2>

            {routinesById !== undefined
              ? Object.keys(routinesById)
                  .filter(
                    (r) =>
                      !routinesById[r].deleted &&
                      checkExistingUsers(routinesById[r].users)
                  )
                  .sort((a, b) => routinesById[a].start - routinesById[b].start)
                  .map((key, index) => {
                    return (
                      <Row key={key}>
                        <Col lg={10}>
                          <Link
                            to={`${match.url}/edit/${key}`}
                            className="routine-card-link"
                          >
                            <PlainCard
                              className={
                                "my-3 p-3 routine-edition-card color-" +
                                index +
                                (routinesById[key].disabled ? " disabled" : "")
                              }
                            >
                              <div className="d-flex w-100 justify-content-between flex-wrap">
                                <div className="flex-grow-0 mr-2 mb-2">
                                  <div
                                    className={
                                      "routine-card-title color-" + index
                                    }
                                  >
                                    <span className="mr-2">
                                      {routinesById[key].name}
                                    </span>
                                  </div>
                                  <div>
                                    <div className="routine-card-time">
                                      {getTimeString(routinesById[key].start)} -{" "}
                                      {getTimeString(routinesById[key].end)}
                                    </div>
                                    <div className="mt-1">
                                      <DayDisplay
                                        days={routinesById[key].days}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="mr-2 mb-2 d-flex align-items-center flex-wrap flex-grow-1">
                                  {routinesById[key].users.map((user) => {
                                    return (
                                      <div className="routine-child-label-wrapper m-1">
                                        <div className="d-flex align-items-center routine-child-label">
                                          <img
                                            alt={usersById[
                                              user
                                            ].icon.toString()}
                                            key={user}
                                            src={
                                              require("assets/ProfileIcons/pi_" +
                                                usersById[user].icon +
                                                ".png").default
                                            }
                                            height="30px"
                                            width="30px"
                                            className="mr-2"
                                          />
                                          <p className="m-0">
                                            {usersById[user].name}
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                                <div className="flex-grow-1">
                                  <div className="d-flex align-items-center justify-content-end flex-wrap w-100 h-100">
                                    {routinesById[key].tasks
                                      .filter(
                                        (task) => !tasksById[task].deleted
                                      )
                                      .map((task, index) => {
                                        if (index <= 2) {
                                          return (
                                            <img
                                              alt={tasksById[
                                                task
                                              ].icon.toString()}
                                              key={task}
                                              src={
                                                require("assets/TaskIcons/task_icon_" +
                                                  tasksById[task].icon +
                                                  ".png").default
                                              }
                                              height="30px"
                                              width="30px"
                                              className="mr-1"
                                            />
                                          );
                                        } else if (index === 3) {
                                          return <p>...</p>;
                                        }
                                        return <></>;
                                      })}
                                  </div>
                                </div>
                              </div>
                            </PlainCard>
                          </Link>
                        </Col>
                        <Col lg={2}>
                          <div className="h-100 w-100 routine-icons d-flex justify-content-around align-items-center">
                            <div>
                              <input
                                type="checkbox"
                                id={"enabled-" + key}
                                name="method"
                                checked={!routinesById[key].disabled}
                                onChange={() => checkChangeHandler(key)}
                                disabled={isLoading}
                              />
                              <label htmlFor={"enabled-" + key}></label>
                            </div>
                            <div onClick={() => deleteRoutineHandler(key)}>
                              <i className="icon-delete"></i>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    );
                  })
              : null}
            <DeleteRoutineModal
              show={showDeleteModal}
              close={() => setShowDeleteModal(false)}
              routine={currentRoutine}
            />
            <div className="mt-3">
              <Link to="/app/routines/edit">
                <BevelButton className="btn btn-primary">
                  <i className="icon-plus"></i> {t("edit.add")}
                </BevelButton>
              </Link>
            </div>
          </PlainCard>
        </Route>
        <Route path="/app/routines/edit">
          <Switch>
            <Route
              path={`${match.path}/edit/:routineId`}
              component={RoutineForm}
            />
            <Route path={`${match.path}/edit`} component={RoutineForm} />
          </Switch>
        </Route>
      </Switch>
    </>
  );
};

export default function Routines() {
  return (
    <SuspenseLoader>
      <Component />
    </SuspenseLoader>
  );
}
