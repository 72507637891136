import { FieldConfig, useField, useFormikContext } from "formik";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface DayPickerProps extends FieldConfig {
  label?: string;
}

const DayPickerField: FunctionComponent<DayPickerProps> = ({
  label,
  ...props
}) => {
  const [field, meta] = useField<number>(props);

  const { setFieldValue } = useFormikContext();

  const { t } = useTranslation();

  const dayIndexes: number[] = [1, 2, 4, 8, 16, 32, 64];

  return (
    <>
      {label ? <label htmlFor={props.name}>{label}</label> : null}
      <div className="day-display d-flex align-items-center mt-2">
        {dayIndexes.map((day) => (
          <div
            key={day}
            className={
              "day-box " +
              (day & field.value ? "" : "inactive ") +
              (day > 16 ? "weekend" : "")
            }
            onClick={() => setFieldValue(props.name, field.value ^ day)}
          >
            {t("days." + day)}
          </div>
        ))}
      </div>
      {meta.touched && meta.error ? (
        <div className="text-danger">{meta.error}</div>
      ) : null}
    </>
  );
};

export default DayPickerField;
