import React, { useEffect, useState } from "react";
import { useHistory, Link, NavLink } from "react-router-dom";

import { useTranslation } from "react-i18next";
import useWindowDimensions from "hooks/useWindowDimensions";
import useUserProfile from "hooks/account/useUserProfile";

import { Navbar, Nav, Container, NavItem } from "react-bootstrap";
import { SuspenseLoader } from "components/graphics/SuspenseLoader";
import Dropdown, { DropdownLink } from "components/inputs/Dropdown/Dropdown";
import { BevelButton } from "components/inputs";
import { useAuth } from "@neurosolutionsgroup/authentication";

const Component = () => {
  const [expanded, setExpanded] = useState(false);

  //  Language management.
  const { t, i18n } = useTranslation(["base", "images"]);

  const windowDimensions = useWindowDimensions();

  const {
    selectors: { dataFetched, userProfile },
  } = useUserProfile();

  const {
    actions: { invalidateSession },
  } = useAuth();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const getOtherLanguage = () => {
    return i18n.language === "en" ? "fr" : "en";
  };

  let history = useHistory();

  const logout = () => {
    invalidateSession();
  };

  const dropdownLinks: DropdownLink[] = [
    {
      onClick: () => history.push("/app/settings"),
      text: <span>{t("nav.settings")}</span>,
    },
    {
      onClick: () => window.open("https://support.kairosgame.com", "_blank"),
      text: (
        <span>
          {t("nav.support")} &nbsp; <i className="fa fa-external-link"></i>
        </span>
      ),
    },
    {
      onClick: () => logout(),
      text: <span>{t("nav.logout")}</span>,
    },
  ];

  useEffect(() => {
    setExpanded(false);
  }, [history.location]);

  return (
    <Navbar
      bg="none"
      className="custom-navbar app"
      expand="lg"
      expanded={expanded}
      onToggle={setExpanded}
    >
      <Container>
        <Navbar.Brand>
          <Link to="/app/dashboard">
            <img
              className="header-logo-simple"
              alt="Kairos Logo"
              src={
                require("assets/kairoslogos/KAIROS-5-logo-simple-seul-blanc-small.png")
                  .default
              }
            />
          </Link>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="navbar-nav" className="btn btn-primary" />

        <Navbar.Collapse id="navbar-nav">
          <Nav className="ml-auto float-right">
            <NavItem>
              <Nav.Link
                as={NavLink}
                to="/app/dashboard"
                activeClassName="selected"
              >
                {t("nav.dashboard")}
              </Nav.Link>
            </NavItem>
            <NavItem>
              <Nav.Link
                as={NavLink}
                to="/app/routines"
                activeClassName="selected"
              >
                {t("nav.routines")}
              </Nav.Link>
            </NavItem>
            {windowDimensions.breakpoints.lg ? (
              <>
                {dataFetched && !userProfile.isSubscribed ? (
                  <BevelButton
                    className="btn btn-primary-alt btn-nav ml-2 mb-2 float-right"
                    onClick={() => history.push("/app/billing")}
                  >
                    {t("nav.subscribe")}
                  </BevelButton>
                ) : null}
                <Dropdown
                  className="ml-2"
                  toggle={
                    <span>
                      <i className="icon-cog"></i>
                    </span>
                  }
                  links={dropdownLinks}
                  wrap={false}
                />
              </>
            ) : (
              <>
                <NavItem>
                  <Nav.Link
                    as={NavLink}
                    to="/app/settings"
                    activeClassName="selected"
                  >
                    {t("nav.settings")}
                  </Nav.Link>
                </NavItem>
                <NavItem>
                  <Nav.Link
                    as={NavLink}
                    to="https://support.kairosgame.com"
                    target="_blank"
                    activeClassName="selected"
                  >
                    {t("nav.support")} &nbsp;{" "}
                    <i className="fa fa-external-link"></i>
                  </Nav.Link>
                </NavItem>
                <NavItem>
                  <Nav.Link>
                    <span onClick={() => logout()}>{t("nav.logout")}</span>
                  </Nav.Link>
                </NavItem>
                {dataFetched && !userProfile.isSubscribed ? (
                  <BevelButton
                    className="btn btn-primary-alt btn-nav mb-2 float-right"
                    onClick={() => history.push("/app/billing")}
                  >
                    {t("nav.subscribe")}
                  </BevelButton>
                ) : null}
              </>
            )}
            <div
              className="language-toggler color-white ml-2 mb-2 px-3 d-flex justify-content-end align-items-center float-right"
              onClick={() => changeLanguage(getOtherLanguage())}
            >
              <b>{getOtherLanguage().toUpperCase()}</b>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default function AppHeader() {
  return (
    <SuspenseLoader>
      <Component />
    </SuspenseLoader>
  );
}
