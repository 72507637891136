import React, { FunctionComponent } from "react";
import { FieldConfig, useField, useFormikContext } from "formik";

const TaskIconPickerField: FunctionComponent<FieldConfig> = ({ ...props }) => {
  const [field, meta] = useField<number>(props);

  const { setFieldValue } = useFormikContext();

  const icons = Array.from({ length: 34 }, (_, index) => index + 1);

  return (
    <>
      <div className="input-wrap">
        <div className="d-flex flex-wrap icon-picker">
          {icons.map((i) => (
            <div
              className={"icon-square " + (i === field.value ? "picked" : "")}
              onClick={() => setFieldValue(field.name, i)}
            >
              <img
                alt={i.toString()}
                src={
                  require("assets/TaskIcons/task_icon_" + i.toString() + ".png")
                    .default
                }
                height="30px"
                width="30px"
              />
            </div>
          ))}
        </div>
      </div>
      {meta.touched && meta.error ? (
        <div className="form-error">{meta.error}</div>
      ) : null}
    </>
  );
};

export default TaskIconPickerField;
