import { User } from "models/Types";
import { executeCloudScript } from "../CloudScript";
import { v4 } from "uuid";

async function updateUser(user: User, sessionTicket: string) {
  const FUNCTION = "updateUsersDefinition";

  return await getUsers(sessionTicket).then((response) => {
    var index = response.users.findIndex((u: any) => u.id === user.id);

    response.users[index].name = user.name;
    response.users[index].icon = user.icon;
    response.users[index].nip = user.pin;
    response.users[index].useImageNip = user.useImageNip;

    const data = {
      userList: {
        version: response.version,
        users: response.users,
      },
    };

    return executeCloudScript(FUNCTION, data, sessionTicket);
  });
}

async function addUser(user: User, sessionTicket: string) {
  const FUNCTION = "updateUsersDefinition";

  return await getUsers(sessionTicket).then((response) => {
    response.users.push({
      id: "USER_" + v4(),
      name: user.name,
      icon: user.icon,
      nip: user.pin,
      birth: 0,
      creation: Math.round(Date.now() / 1000),
      gameProgression: null,
      avatarCustomization: null,
      taskHistory: null,
    });

    const data = {
      userList: {
        version: response.version,
        users: response.users,
      },
    };

    return executeCloudScript(FUNCTION, data, sessionTicket);
  });
}

async function getUsers(sessionTicket: string) {
  const FUNCTION = "loadUsers";

  return executeCloudScript(FUNCTION, {}, sessionTicket);
}

export { updateUser, getUsers, addUser };
