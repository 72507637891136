import React from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { Navbar, Nav, Container } from "react-bootstrap";
import { SuspenseLoader } from "components/graphics/SuspenseLoader";

const Component = () => {
  //  Language management.
  const { t, i18n } = useTranslation(["base", "images"]);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const getOtherLanguage = () => {
    return i18n.language === "en" ? "fr" : "en";
  };

  return (
    <Navbar bg="none" className="custom-navbar" expand="lg">
      <Container>
        <Navbar.Brand>
          <a href="https://kairosgame.com">
            <img
              className="header-logo"
              alt="Kairos Logo"
              src={
                require("assets/kairoslogos/" + t("images:logosimple") + ".png")
                  .default
              }
            />
          </a>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="navbar-nav" className="btn btn-primary" />

        <Navbar.Collapse id="navbar-nav">
          <Nav className="ml-auto float-right">
            <div className="ml-2 mb-2">
              <div className="button-wrap">
                <Link to="/account/login">
                  <button className="btn btn-primary-alt px-4">
                    {t("nav.login")}
                  </button>
                </Link>
              </div>
            </div>
            <div
              className="language-toggler color-white ml-2 mb-2 px-3 d-flex justify-content-end align-items-center float-right"
              onClick={() => changeLanguage(getOtherLanguage())}
            >
              <b>{getOtherLanguage().toUpperCase()}</b>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default function Header() {
  return (
    <SuspenseLoader>
      <Component />
    </SuspenseLoader>
  );
}
