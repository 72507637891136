import React, { FunctionComponent, useState } from "react";
import { PlayFabRoutine, Task } from "models/Types";

import NameModal from "./NameModal";
import IconModal from "./IconModal";
import AlertModal from "./AlertModal";
import DeleteTaskModal from "./DeleteTaskModal";

import { FieldConfig, useField, useFormikContext } from "formik";
import { v4 } from "uuid";
import { getTimeString } from "services/DateTime";

import { PlainCard } from "components/layout/cards";
import { BevelButton } from "components/inputs";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface TaskEditorProps extends FieldConfig {
  label?: string;
}

const TaskEditor: FunctionComponent<TaskEditorProps> = ({
  label,
  ...props
}) => {
  const { t } = useTranslation("routines");

  //  Formik.
  const [field, meta] = useField<Task[]>(props);

  const { values, setFieldValue } = useFormikContext();

  //  Control modals.
  const [currentTaskId, setCurrentTaskId] = useState("");
  const [showNameModal, setShowNameModal] = useState(false);
  const [showIconModal, setShowIconModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const showModal = (taskId: string, modal: string) => {
    //  Update task Id.
    setCurrentTaskId(taskId);

    //  Show modal.
    switch (modal) {
      case "name":
        setShowNameModal(true);
        break;
      case "icon": {
        setShowIconModal(true);
        break;
      }
      case "alert": {
        setShowAlertModal(true);
        break;
      }
      case "delete": {
        setShowDeleteModal(true);
        break;
      }
    }
  };

  //  Functions.
  //  Add a copy of an existing task to the task list.
  const copyTask = (taskId: string) => {
    const tValues = values as PlayFabRoutine;

    const task = tValues.tasks.find((t) => t.id === taskId);

    if (task) {
      var newTask = { ...task };

      newTask.id = "TASK_" + v4();

      setFieldValue(props.name, [...tValues.tasks, newTask]);
    }
  };

  //  Add a new task with default values to the task list.
  const addTask = () => {
    setFieldValue(props.name, [
      ...field.value,
      {
        id: "TASK_" + v4(),
        name: "New Task",
        icon: 1,
        reminder: 0,
        routine: "0",
        creation: Math.floor(Date.now() / 1000),
      },
    ]);
  };

  //  Toggle the reminder for a task. When switched on the time is set to the routine start time.
  const toggleReminder = (taskId: string) => {
    const tValues = values as PlayFabRoutine;

    var newTasks = [...tValues.tasks];

    var index = newTasks.findIndex((t) => t.id === taskId);

    newTasks[index].reminder =
      newTasks[index].reminder === 0 ? tValues.start : 0;

    setFieldValue(props.name, newTasks);
  };

  const moveTaskUp = (index: number) => {
    const tValues = values as PlayFabRoutine;

    var newTasks = [...tValues.tasks];

    var taskToSwitch = { ...newTasks[index] };

    newTasks[index] = newTasks[index - 1];

    newTasks[index - 1] = taskToSwitch;

    setFieldValue(props.name, newTasks);
  };

  const moveTaskDown = (index: number) => {
    const tValues = values as PlayFabRoutine;

    var newTasks = [...tValues.tasks];

    var taskToSwitch = { ...newTasks[index] };

    newTasks[index] = newTasks[index + 1];

    newTasks[index + 1] = taskToSwitch;

    setFieldValue(props.name, newTasks);
  };

  return (
    <>
      <div>
        {label ? <label htmlFor={props.name}>{label}</label> : null}
        <div>
          {field.value
            .filter((task) => !task.deleted)
            .map((task, index) => (
              <Row key={task.id}>
                <Col md={10}>
                  <PlainCard className="p-3 mb-2 d-flex flex-row align-items-center">
                    <div className="d-flex flex-column mr-2">
                      <BevelButton
                        className="btn btn-primary btn-mini"
                        disabled={index === 0}
                        onClick={() => moveTaskUp(index)}
                        type="button"
                      >
                        <i className="icon-uparrow"></i>
                      </BevelButton>
                      <BevelButton
                        className="btn btn-primary btn-mini"
                        disabled={
                          index ===
                          field.value.filter((task) => !task.deleted).length - 1
                        }
                        onClick={() => moveTaskDown(index)}
                        type="button"
                      >
                        <i className="icon-downarrow"></i>
                      </BevelButton>
                    </div>
                    <img
                      alt={task.icon?.toString()}
                      src={
                        require("assets/TaskIcons/task_icon_" +
                          task.icon +
                          ".png").default
                      }
                      onClick={() => showModal(task.id, "icon")}
                      className="mr-3 p-1 task-icon routine-task-icon-picker"
                    />

                    <p
                      className="flex-grow-1 m-0 routine-task-edit-name"
                      onClick={() => showModal(task.id, "name")}
                    >
                      {task.name}
                    </p>

                    <div className="ml-auto d-flex flex-row task-edit-alert align-items-center routine-task-edit-alert">
                      <div onClick={() => showModal(task.id, "alert")}>
                        <p className="m-0">
                          {task.reminder === 0
                            ? "None"
                            : getTimeString(task.reminder)}
                        </p>
                      </div>

                      <div
                        className="d-flex align-items-center"
                        onClick={() => toggleReminder(task.id)}
                      >
                        {task.reminder === 0 ? (
                          <i className="icon-alert-dis icon-lg ml-2"></i>
                        ) : (
                          <i className="icon-alert-en icon-lg ml-2"></i>
                        )}
                      </div>
                    </div>
                  </PlainCard>
                </Col>
                <Col md={2}>
                  <div className="h-100 w-100 routine-icons d-flex justify-content-around align-items-center">
                    <div onClick={() => copyTask(task.id)}>
                      <i className="icon-copy icon-lg"></i>
                    </div>
                    <div onClick={() => showModal(task.id, "delete")}>
                      <i className="icon-delete icon-lg"></i>
                    </div>
                  </div>
                </Col>
              </Row>
            ))}
          <NameModal
            show={showNameModal}
            close={() => setShowNameModal(false)}
            taskId={currentTaskId}
            name={props.name}
          />
          <IconModal
            show={showIconModal}
            close={() => setShowIconModal(false)}
            taskId={currentTaskId}
            name={props.name}
          />
          <AlertModal
            show={showAlertModal}
            close={() => setShowAlertModal(false)}
            taskId={currentTaskId}
            name={props.name}
          />
          <DeleteTaskModal
            show={showDeleteModal}
            close={() => setShowDeleteModal(false)}
            taskId={currentTaskId}
            name={props.name}
          />
          <div>
            <BevelButton
              className="btn btn-primary"
              type="button"
              onClick={addTask}
            >
              <i className="icon-plus"></i> {t("edit.tasks.add")}
            </BevelButton>
          </div>
        </div>
        {meta.touched && meta.error ? (
          <div className="form-error">{meta.error}</div>
        ) : null}
      </div>
    </>
  );
};

export default TaskEditor;
