import { HookResult } from "hooks/HookResult";
import { PlayFabRoutine } from "models/Types";
import { useContext } from "react";
import RoutineContext, { RoutinesById } from "./RoutineContext";
import * as PlayFabAPI from "services/api/playFabApi";
import { NormalizrResult, parsePlayFabRoutineData } from "./Tools";
import { routine, task } from "models/Schema";
import { normalize } from "normalizr";
import { merge } from "lodash";
import { useAuth } from "@neurosolutionsgroup/authentication";
import TaskContext from "./TaskContext";

export interface useRoutinesSelectors {
  routineIds: string[];
  routinesById: RoutinesById;
}

export interface useRoutineActions {
  updateRoutine: (updatedRoutine: PlayFabRoutine) => Promise<void>;
}

const useRoutines = (): HookResult<useRoutinesSelectors, useRoutineActions> => {
  const {
    routineIds,
    setRoutineIds,
    routinesById,
    setRoutinesById,
  } = useContext(RoutineContext);

  const { setTasksById } = useContext(TaskContext);

  const {
    actions: { getAuthentication },
  } = useAuth();

  const updateRoutine = async (updatedRoutine: PlayFabRoutine) => {
    return PlayFabAPI.Routine.Routines.updateRoutine(
      updatedRoutine,
      getAuthentication().sessionTicket
    ).then((response) => {
      //  Renormalize the data to be stored in state.
      const rawData = {
        routines: parsePlayFabRoutineData([response]),
      };

      var data = normalize(rawData, {
        tasks: [task],
        routines: [routine],
      }) as NormalizrResult;

      //  Merge updated routine and tasks with state.
      setRoutineIds((prevState) => merge(prevState, data.result.routines));
      setRoutinesById((prevState) => merge(prevState, data.entities.routines));

      setTasksById((prevState) => merge(prevState, data.entities.tasks));
    });
  };

  return {
    selectors: {
      routineIds,
      routinesById,
    },
    actions: {
      updateRoutine,
    },
  };
};

export default useRoutines;
