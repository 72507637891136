import React from "react";
import { useTranslation } from "react-i18next";

import { Col, Row } from "react-bootstrap";

import { SuspenseLoader } from "components/graphics";
import { PlainCard } from "components/layout/cards";

const Component = () => {
  //  Hooks  //
  //  Localization.
  const { t } = useTranslation(["dashboard", "base"]);

  return (
    <PlainCard className="p-3">
      <Row>
        <Col md={7} sm={6} className="d-flex align-items-center pt-3">
          <div className="w-100">
            <h3>{t("message-nochildren")}</h3>
          </div>
        </Col>
        <Col md={5} sm={6}>
          <div>
            <div>
              <div>
                <a
                  href="https://kairos.app.link/hvKZt0EXJ7"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt={t("base:components.googleplayalt")}
                    className="w-100"
                    src={
                      "https://play.google.com/intl/en_us/badges/static/images/badges/" +
                      t("base:components.googleplaybadge") +
                      "_badge_web_generic.png"
                    }
                  />
                </a>
              </div>
            </div>
            <div className="w-100">
              <div>
                <a
                  href="https://kairos.app.link/hvKZt0EXJ7"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt={t("base:components.appstorealt")}
                    className="w-100 p-4"
                    src={
                      require("assets/appstore/AppStore" +
                        t("base:components.appstorebadge") +
                        ".svg").default
                    }
                  />
                </a>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </PlainCard>
  );
};

export default function NoChildPlaceholder() {
  return (
    <SuspenseLoader>
      <Component />
    </SuspenseLoader>
  );
}
