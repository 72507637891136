import axios from "axios";

async function sendConfirmationEmail(sessionTicket: string) {
  var headers = {
    Authorization: sessionTicket,
  };

  return axios.get(
    process.env.REACT_APP_KAIROS_API_ENDPOINT + "/api/ConfirmationEmail",
    { headers: headers }
  );
}

export { sendConfirmationEmail };
