import { PlayFabRoutine } from "models/Types";
import { executeCloudScript } from "../CloudScript";

async function updateRoutine(routine: PlayFabRoutine, sessionTicket: string) {
  const FUNCTION_UPDATEROUTINE = "updateRoutineDefinition";

  return executeCloudScript(
    FUNCTION_UPDATEROUTINE,
    { routine: routine },
    sessionTicket
  ).then((response) => {
    return response;
  });
}

export { updateRoutine };
