import React, { FunctionComponent, HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import SuspenseLoader from "./SuspenseLoader";

interface DayDisplayProps extends HTMLAttributes<HTMLDivElement> {
  days: number;
}

const Component: FunctionComponent<DayDisplayProps> = ({ days, ...props }) => {
  const { t } = useTranslation();

  const dayIndexes: number[] = [1, 2, 4, 8, 16, 32, 64];

  return (
    <div className={"day-display d-flex " + props.className}>
      {dayIndexes.map((day) => (
        <div
          key={day}
          className={
            "day-box " +
            (day & days ? "" : "inactive ") +
            (day > 16 ? "weekend" : "")
          }
        >
          {t("days." + day)}
        </div>
      ))}
    </div>
  );
};

export default function DayDisplay(props: DayDisplayProps) {
  return (
    <SuspenseLoader>
      <Component {...props} />
    </SuspenseLoader>
  );
}
