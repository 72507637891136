import clsx from "clsx";
import React, { FunctionComponent, HTMLAttributes } from "react";
import LogoHeader from "../../graphics/LogoHeader";

interface LogoCardProps {
  fullWidth?: boolean;
}

const LogoCard: FunctionComponent<
  HTMLAttributes<HTMLElement> & LogoCardProps
> = ({ className, fullWidth = false, ...props }) => {
  return (
    <div
      className={clsx("card-wrap", "logo-card", {
        "col-xl-5 col-lg-6 col-md-7 col-sm-10": !fullWidth,
      })}
    >
      <div className={"card card-logo p-3 pt-5 " + className} {...props}>
        <LogoHeader />
        {props.children}
      </div>
    </div>
  );
};

export default LogoCard;
