import useStripe from "hooks/billing/useStripe";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getLocalCurrency, LocalizationInfo } from "services/Location";
import ProductCard from "./components/ProductCard";
import * as API from "services/api/kairosApi";
import { useAuth } from "@neurosolutionsgroup/authentication";
import { useStripe as useStripeHook } from "@stripe/react-stripe-js";
import { Loader } from "@neurosolutionsgroup/components";
import useUserProfile from "hooks/account/useUserProfile";
import { Coupon } from "hooks/billing/types";

const Products = () => {
  const { t } = useTranslation(["settings"]);
  const [localization, setLocalization] = useState<
    LocalizationInfo | undefined
  >(undefined);
  const [loading, setLoading] = useState(false);

  const {
    selectors: { prices, referralCoupon },
    actions: { getPrices },
  } = useStripe();

  const {
    selectors: { userProfile },
  } = useUserProfile();

  const stripe = useStripeHook();

  const {
    actions: { getAuthentication },
  } = useAuth();

  useEffect(() => {
    getPrices();
    getLocalCurrency().then((response) => {
      setLocalization(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkout = (priceId: string) => {
    if (localization) {
      setLoading(true);
      API.Account.Stripe.createCheckoutSession(
        priceId,
        getAuthentication().sessionTicket,
        localization
      ).then((response) => {
        stripe?.redirectToCheckout({
          sessionId: response.sessionId,
        });
      });
    }
  };

  const getReferralInformation = useCallback((): Coupon | undefined => {
    return userProfile.referrerName && referralCoupon
      ? referralCoupon
      : undefined;
  }, [userProfile, referralCoupon]);

  return (
    <>
      {prices.length > 0 && localization && (
        <>
          {loading && <Loader />}
          <Row>
            <Col md={6}>
              <ProductCard
                interval="month"
                prices={prices}
                currency={localization.currency}
                title={t("billing.producttype.monthly")}
                onClick={checkout}
              />
            </Col>
            <Col md={6}>
              <ProductCard
                interval="year"
                prices={prices}
                currency={localization.currency}
                title={t("billing.producttype.yearly")}
                onClick={checkout}
                coupon={getReferralInformation()}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default Products;
