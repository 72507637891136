import { Price } from "hooks/billing/types";
import { StripePrice } from "../Stripe";

export const stripePriceToAppPrice = (
  stripePrice: StripePrice
): Price | null => {
  if (!["year", "month"].includes(stripePrice.recurring.interval)) {
    return null;
  } else if (!["usd", "cad", "eur"].includes(stripePrice.currency)) {
    return null;
  }

  return {
    id: stripePrice.id,
    interval: stripePrice.recurring.interval as "year" | "month",
    currency: stripePrice.currency as "usd" | "cad" | "eur",
    unitAmount: stripePrice.unitAmount,
    unitAmountDecimal: stripePrice.unitAmountDecimal,
    productId: stripePrice.productId,
  };
};
