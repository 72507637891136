import { BevelButton } from "components/inputs";
import { PlainCard } from "components/layout/cards";
import { CardPage } from "components/layout/pages";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

const PaymentSuccessful = () => {
  const { t } = useTranslation(["account"]);
  const history = useHistory();

  return (
    <CardPage>
      <PlainCard className="bg-beige p-4" outerClasses="success-card">
        <div>
          <h2>{t("callbacks.payment.success.title")}</h2>
          <h3>{t("callbacks.payment.success.subtitle")}</h3>
          <p className="text-center">{t("callbacks.payment.success.text")}</p>
          <div className="mt-2 d-flex justify-content-center">
            <BevelButton
              className="btn btn-primary"
              onClick={() => history.push("/app/dashboard")}
            >
              {t("callbacks.payment.success.button")}
            </BevelButton>
          </div>
        </div>
      </PlainCard>
      <img
        className="success-banner"
        src={require("assets/Backgrounds/success-banner.svg").default}
        alt="Success"
      />
    </CardPage>
  );
};

export default PaymentSuccessful;
