import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Redirect, useLocation } from "react-router-dom";
import queryString from "query-string";

import TrackingAPI, { AccountType } from "services/tracking/TrackingAPI";
import {
  AuthError,
  LoginPage,
  useAuth,
} from "@neurosolutionsgroup/authentication";

//  Components.
import { LogoCard } from "components/layout/cards";
import { CardPage } from "components/layout/pages";
import { store } from "react-notifications-component";
import { dangerNotification } from "components/notifications/notification";

const Login = () => {
  let urlParams = useLocation().search;
  let params = queryString.parse(urlParams);
  let [success, setSuccess] = useState<boolean>(false);

  const { t } = useTranslation(["account", "forms"]);

  const {
    actions: { isAuthenticated },
  } = useAuth();

  const onSuccess = (response: { playFabId: string }, email: string) => {
    TrackingAPI.Account.userLoggedIn(
      response.playFabId,
      AccountType.Email,
      email
    ).catch(() => {
      console.log("[Login] Tracking Error");
    });
    setSuccess(true);
  };

  const onFailure = (err: AuthError) => {
    store.addNotification(
      dangerNotification(
        t("forms:errors.title"),
        t("forms:errors.general") + ": " + t("forms:errors." + err.code),
        false
      )
    );
  };

  return (
    <CardPage>
      {isAuthenticated() ? (
        <Redirect to={{ pathname: "/app", search: urlParams }} />
      ) : null}

      <LogoCard className="bg-beige">
        <LoginPage
          autoCheckout={params.autoCheckout ? true : false}
          additionnalTitle={
            params.source === "pinreset"
              ? t("login.sources.pinreset.message")
              : undefined
          }
          onSuccess={onSuccess}
          onFailure={onFailure}
        />

        <hr />

        <p className="text-center">
          {t("registration.menu.forgottendescription")}{" "}
          <Link to="/account/forgottenpassword">
            {t("registration.menu.forgottenlink")}
          </Link>
        </p>

        <hr />

        <p className="text-center">
          <Link to="/">{t("login.create")}</Link>
        </p>
      </LogoCard>

      {success ? (
        <div>
          {params.source === "pinreset" ? (
            <Redirect to={{ pathname: "/app/settings", search: urlParams }} />
          ) : params.source === "clinicaltrial" ? (
            <Redirect to={{ pathname: "/app/promocode", search: urlParams }} />
          ) : (
            <Redirect to={{ pathname: "/app/dashboard", search: urlParams }} />
          )}
        </div>
      ) : null}
    </CardPage>
  );
};

export default Login;
