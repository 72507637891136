import { HookResult } from "hooks/HookResult";
import { useContext } from "react";
import { UserProfile } from "services/UserProfile";
import UserProfileContext from "./UserProfileContext";
import * as API from "services/api/kairosApi";
import * as PlayFabAPI from "services/api/playFabApi";
import { useAuth } from "@neurosolutionsgroup/authentication";
import TrackingAPI from "services/tracking";

interface UseUserProfileSelectors {
  dataFetched: boolean;
  userProfile: UserProfile;
}

interface UseUserProfileActions {
  updateUserProfile: (args: UserInternalData) => Promise<boolean>;
  sendConfirmationEmail: () => Promise<boolean>;
  resetPin: (pin: string) => Promise<boolean>;
  updateLanguage: (language: string) => Promise<string>;
}

type UserInternalData = {
  firstName: string;
  lastName: string;
  city: string;
  email: string;
};

const useUserProfile = (): HookResult<
  UseUserProfileSelectors,
  UseUserProfileActions
> => {
  const { dataFetched, userProfile, setUserProfile } = useContext(
    UserProfileContext
  );

  const {
    actions: { getAuthentication },
  } = useAuth();

  const KNOWN_ERRORS = ["2100"];

  const updateUserProfile = async (
    args: UserInternalData
  ): Promise<boolean> => {
    return API.Account.Data.updateUserData(
      {
        userInternalData: args,
      },
      getAuthentication().sessionTicket
    ).then((response) => {
      TrackingAPI.InApp.settingsUpdated(response);
      setUserProfile(response);

      return true;
    });
  };

  const sendConfirmationEmail = async (): Promise<boolean> => {
    return API.Account.Security.sendConfirmationEmail(
      getAuthentication().sessionTicket
    ).then(() => true);
  };

  const resetPin = async (pin: string): Promise<boolean> => {
    return PlayFabAPI.Account.updateParentPin(
      pin,
      getAuthentication().sessionTicket
    )
      .then(() => true)
      .catch((error) => {
        const errorMessage = error as string;

        if (KNOWN_ERRORS.includes(errorMessage)) {
          return Promise.reject(new Error(errorMessage));
        } else {
          return Promise.reject(new Error("unkown"));
        }
      });
  };

  const updateLanguage = async (language: string): Promise<string> => {
    return PlayFabAPI.Account.updateProfileLanguage(
      language,
      getAuthentication().entityToken
    )
      .then(() => {
        return language;
      })
      .catch((error) => {
        const errorMessage = error as string;

        if (KNOWN_ERRORS.includes(errorMessage)) {
          return Promise.reject(new Error(errorMessage));
        } else {
          return Promise.reject(new Error("unkown"));
        }
      });
  };

  return {
    selectors: {
      dataFetched,
      userProfile,
    },
    actions: {
      updateUserProfile,
      sendConfirmationEmail,
      resetPin,
      updateLanguage,
    },
  };
};

export default useUserProfile;
