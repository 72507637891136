import React, { useRef } from "react";
import clsx from "clsx";
import { LogoCard } from "components/layout/cards";
import { FormButton } from "@neurosolutionsgroup/components";
import { useTranslation } from "react-i18next";

interface ReferredRegistrationProps {
  referrerName?: string;
}

const ReferredRegistration: React.FC<ReferredRegistrationProps> = ({
  referrerName,
  ...props
}) => {
  const ROOT_CLASS = "referred-registration-page";

  const { t } = useTranslation("referral");

  const topRef = useRef<HTMLDivElement>(null);

  return (
    <div className={ROOT_CLASS}>
      <div className={clsx(ROOT_CLASS + "__registration", "row")} ref={topRef}>
        <div className={clsx(ROOT_CLASS + "__registration-text", "col col-md")}>
          <h1>
            {t("registration.registration.title") + (referrerName ?? "ton ami")}
            !
          </h1>
          <p>{t("registration.registration.subtitle")}</p>
        </div>
        <div className={clsx(ROOT_CLASS + "__registration-form", "col col-md")}>
          <img
            className="affiliate_form_kairos"
            alt="Kairos Logo"
            src="https://kairosgame.com/media/1721/kairos-thumbs-up.png"
          />
          <LogoCard fullWidth={true} className="bg-beige">
            {props.children}
          </LogoCard>
        </div>
      </div>
      <div className={clsx(ROOT_CLASS + "__information", "row")}>
        <div className="col col-sm-12">
          <h4>{t("registration.information.title")}</h4>
          <h3>{t("registration.information.subtitle")}</h3>
        </div>
        <div className="col col-sm-4">
          <img
            className="affiliate-images"
            src="https://kairosgame.com/media/1748/kairos-refere-un-ami.png"
            alt=""
          />
          <p>
            <b>{t("registration.information.text.discount.title")}</b>
          </p>
          <p>{t("registration.information.text.discount.text")}</p>
        </div>

        <div className="col col-sm-4">
          <img
            className="affiliate-images"
            src="https://kairosgame.com/media/1746/kairos-costumes-amusants.png"
            alt=""
          />

          <p>
            <b>{t("registration.information.text.reward.title")}</b>
          </p>
          <p>{t("registration.information.text.reward.text")}</p>
        </div>

        <div className="col col-sm-4">
          <img
            className="affiliate-images"
            src="https://kairosgame.com/media/1747/kairos-recoit-des-recompenses.png"
            alt=""
          />
          <p>
            <b>{t("registration.information.text.routine.title")}</b>
          </p>
          <p>{t("registration.information.text.routine.text")}</p>
        </div>
        <div className="col col-sm-12 d-flex justify-content-center">
          <div>
            <FormButton
              onClick={() => {
                topRef.current?.scrollIntoView({ behavior: "smooth" });
              }}
              value={t("registration.information.action")}
            />
          </div>
        </div>
        <svg
          viewBox="0 0 1440 84"
          id="icon__bg__section-t"
          xmlns="http://www.w3.org/2000/svg"
          className="section__top js-svg"
        >
          <path
            className="dark"
            fill="#3B6466"
            d="M0 6.5l388.2 42.1L805.2 0l204.2 48.6L1327.1 0 1440 26.9V84H0V6.5z"
          ></path>
          <path
            className="light"
            fill-rule="evenodd"
            clip-rule="evenodd"
            fill="#629D9B"
            d="M1327.1 0L1440 26.9v29.8L1327.1 0zM805.2 0L625.5 70.7 388.2 48.6 0 82.5l635.3-10.9-5.5-.5 379.6-22.6L805.2 0zm-636 24.8L0 82.5v-76l169.2 18.3zM1440 65.5l-430.6-17 172.2-26.3L1440 65.5z"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default ReferredRegistration;
