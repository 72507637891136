import { BevelButton } from "components/inputs";
import { PlainCard } from "components/layout/cards";
import { CardPage } from "components/layout/pages";
import React from "react";
import { useTranslation } from "react-i18next";

const PaymentFailed = () => {
  const { t } = useTranslation(["account"]);

  return (
    <CardPage>
      <PlainCard className="bg-beige p-4" outerClasses="success-card">
        <div>
          <h2>{t("callbacks.payment.fail.title")}</h2>
          <h3>{t("callbacks.payment.fail.subtitle")}</h3>
          <p className="text-center">{t("callbacks.payment.fail.text")}</p>
          <div className="mt-2 d-flex justify-content-center">
            <BevelButton
              className="btn btn-primary"
              onClick={() => {
                window.location.href =
                  "https://support.kairosgame.com/hc/en-us";
                return null;
              }}
            >
              {t("callbacks.payment.fail.button")}
            </BevelButton>
          </div>
        </div>
      </PlainCard>
    </CardPage>
  );
};

export default PaymentFailed;
