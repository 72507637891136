import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Registration, Login, PasswordRecovery } from ".";
import Header from "components/layout/header/Header";
import CompleteRegistration from "./registration/CompleteRegistration";
import RecoverAccount from "./passwordrecovery/RecoverAccount";

const Account = () => {
  return (
    <div className="background-body background">
      <Route path="/account">
        <Header />
      </Route>
      <Switch>
        <Route exact path="/account">
          <Registration />
        </Route>
        <Route
          path="/account/completeregistration"
          component={CompleteRegistration}
        />
        <Route path="/account/login">
          <Login />
        </Route>
        <Route path="/account/forgottenpassword" component={RecoverAccount} />
        <Route path="/account/recoveraccount" component={RecoverAccount} />
        <Route path="/account/passwordrecovery" component={PasswordRecovery} />
        <Redirect to="/account" />
      </Switch>
    </div>
  );
};

export default Account;
