import React from "react";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

import { CardPage } from "components/layout/pages";
import { LogoCard } from "components/layout/cards";
import { SuspenseLoader } from "components/graphics/SuspenseLoader";
import TrackingAPI from "services/tracking";

//  Interface displayed after confirming email.
function Component() {
  const { t } = useTranslation(["account"]);

  //  Get params.
  let url = useLocation().search;
  let params = queryString.parse(url);

  if (params.token != null) {
    TrackingAPI.Account.emailConfirmed();
  }

  return (
    <CardPage>
      <LogoCard className="bg-beige">
        <div>
          <h2>{t("callbacks.emailconfirmed.title")}</h2>
          <hr />
          <p className="text-center">
            {t("callbacks.emailconfirmed.subtitle")}
          </p>
        </div>
      </LogoCard>
    </CardPage>
  );
}

export default function EmailConfirmed() {
  return (
    <SuspenseLoader>
      <Component />
    </SuspenseLoader>
  );
}
