import { ReactNotificationOptions } from "react-notifications-component";

export function successNotification(
  title: string,
  message: string,
  timeOut = true
) {
  return notification(title, message, "success", timeOut ? 5000 : 0);
}

export function dangerNotification(
  title: string,
  message: string,
  timeOut = true
) {
  return notification(title, message, "danger", timeOut ? 5000 : 0);
}

function notification(
  title: string,
  message: string,
  type: "success" | "danger",
  dismissTime: number
): ReactNotificationOptions {
  return {
    title: title,
    message: message,
    type: type,
    insert: "top",
    container: "bottom-left",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: dismissTime,
      onScreen: true,
      showIcon: true,
    },
    touchSlidingExit: {
      duration: 400,
      timingFunction: "ease-out",
      delay: 0,
    },
  };
}
