import { schema } from "normalizr";

export const user = new schema.Entity("users");

export const task = new schema.Entity("tasks");

export const routine = new schema.Entity("routines", {
  tasks: [task],
  users: [user],
});
