import axios from "axios";
import { PlayFabLoginResponse } from "models/Types";
import { executeCloudScript } from "../CloudScript";

const TITLE_ID = 18652;

//  API URI.
const PLAYFAB_API_BASE = "https://" + TITLE_ID + ".playfabapi.com/";

async function loginWithFacebook(
  accessToken: string
): Promise<PlayFabLoginResponse> {
  const ENDPOINT = "Client/LoginWithFacebook";

  const data = {
    AccessToken: accessToken,
    CreateAccount: false,
    TitleId: TITLE_ID,
  };

  return axios
    .post(PLAYFAB_API_BASE + ENDPOINT, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
}

async function loginWithOpenIdConnect(
  idToken: string
): Promise<PlayFabLoginResponse> {
  const ENDPOINT = "Client/LoginWithOpenIdConnect";

  const data = {
    ConnectionId: "",
    CreateAccount: false,
    IdToken: idToken,
    TitleId: TITLE_ID,
  };

  return axios
    .post(PLAYFAB_API_BASE + ENDPOINT, data)
    .then((response) => {
      return response.data as PlayFabLoginResponse;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function registerwithFacebook(
  accessToken: string
): Promise<PlayFabLoginResponse> {
  const ENDPOINT = "Client/LoginWithFacebook";

  const data = {
    AccessToken: accessToken,
    CreateAccount: true,
    TitleId: TITLE_ID,
  };

  return axios
    .post(PLAYFAB_API_BASE + ENDPOINT, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
}

//  Update user contact email.
async function updateContactEmail(email: string, sessionTicket: string) {
  const ENDPOINT = "Client/AddOrUpdateContactEmail";

  const data = {
    EmailAddress: email,
  };

  const headers = {
    "X-Authorization": sessionTicket,
  };

  return axios
    .post(PLAYFAB_API_BASE + ENDPOINT, data, {
      headers: headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return Promise.reject(error);
    });
}

//  Update user profile language.
async function updateProfileLanguage(language: string, entityToken: string) {
  const ENDPOINT = "Profile/SetProfileLanguage";

  const data = {
    Language: language,
  };

  const headers = {
    "X-EntityToken": entityToken,
  };

  return axios
    .post(PLAYFAB_API_BASE + ENDPOINT, data, {
      headers: headers,
    })
    .then((response) => {
      return updateLanguageSetting(language, entityToken).then(() => {
        return response.data;
      });
    })
    .catch((error) => {
      console.log(error);
      return Promise.reject(error);
    });
}

interface masterSettings {
  version: number;
  settings: {
    nip?: string;
    language?: string;
    FreeTrialExtended?: boolean;
    FreeTrialExtendedDate?: number;
  };
}

async function updateParentPin(pin: string, sessionTicket: string) {
  const FUNCTION_GETMASTERSETTINGS = "loadMasterSettings";
  const FUNCTION_UPDATEMASTERSETTINGS = "updateMasterSettings";

  return executeCloudScript(
    FUNCTION_GETMASTERSETTINGS,
    null,
    sessionTicket
  ).then((response) => {
    var masterSettings: masterSettings = response;

    masterSettings.settings.nip = pin;

    return executeCloudScript(
      FUNCTION_UPDATEMASTERSETTINGS,
      {
        settings: masterSettings,
      },
      sessionTicket
    );
  });
}

async function updateLanguageSetting(language: string, sessionTicket: string) {
  const FUNCTION_GETMASTERSETTINGS = "loadMasterSettings";
  const FUNCTION_UPDATEMASTERSETTINGS = "updateMasterSettings";

  return executeCloudScript(
    FUNCTION_GETMASTERSETTINGS,
    null,
    sessionTicket
  ).then((response) => {
    var masterSettings: masterSettings = response;

    masterSettings.settings.language =
      language.toLowerCase() === "en" ? "English" : "French";

    return executeCloudScript(
      FUNCTION_UPDATEMASTERSETTINGS,
      {
        settings: masterSettings,
      },
      sessionTicket
    );
  });
}

async function addExtendedFreeTrial(sessionTicket: string) {
  const FUNCTION_GETMASTERSETTINGS = "loadMasterSettings";
  const FUNCTION_UPDATEMASTERSETTINGS = "updateMasterSettings";

  return executeCloudScript(
    FUNCTION_GETMASTERSETTINGS,
    null,
    sessionTicket
  ).then((response) => {
    var masterSettings: masterSettings = response;

    masterSettings.settings.FreeTrialExtended = true;
    masterSettings.settings.FreeTrialExtendedDate = Math.round(
      Date.now() / 1000
    );

    return executeCloudScript(
      FUNCTION_UPDATEMASTERSETTINGS,
      {
        settings: masterSettings,
      },
      sessionTicket
    );
  });
}

export {
  updateContactEmail,
  updateProfileLanguage,
  loginWithFacebook,
  loginWithOpenIdConnect,
  registerwithFacebook,
  updateParentPin,
  addExtendedFreeTrial,
};
