import { RawDataResult } from "models/Types";
import { executeCloudScript } from "./CloudScript";

async function getData(sessionTicket: string) {
  const FUNCTION_GETUSERS = "loadUsers";
  const FUNCTION_GETROUTINES = "loadRoutines";

  return executeCloudScript(FUNCTION_GETUSERS, {}, sessionTicket).then(
    (usersResponse) => {
      return executeCloudScript(FUNCTION_GETROUTINES, {}, sessionTicket).then(
        (routinesResponse) => {
          return {
            users: usersResponse,
            routines: routinesResponse.routines,
          } as RawDataResult;
        }
      );
    }
  );
}

export { getData };
